

import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { AcceptedValueCategoriesServiceProxy, AcceptedValueDto } from '@shared/service-proxies/service-proxies';

@Injectable()
export class AppNavigationService {
    categoriesData: AcceptedValueDto[];
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _AcceptedValueCategoryServiceProxy: AcceptedValueCategoriesServiceProxy
    ) {
    }

    getMenu(requestNumber?: string): AppMenu {

        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', '', 'menu-icons chart-histogram', '/app/main/dashboard', undefined, undefined, undefined, undefined, () => {
                return this._appSessionService.user.isSubscriptionActivated;
            }),

            new AppMenuItem('Requests', 'Pages.Requests', 'menu-icons Pages-Requests-icon', '/app/admin/requests', undefined, undefined, undefined, undefined, () => {
                return this._appSessionService.user.isSubscriptionActivated;
            }, undefined, requestNumber == '0' ? undefined : requestNumber, 'badge-danger'),

            new AppMenuItem('FarmPlanning', 'Pages.FarmPlanning', 'menu-icons Farm-Planning-icon', '', [], [
                // new AppMenuItem('StaffManagement', '', '', '/app/main/Sector'),
                new AppMenuItem('Users', 'Pages.Users', '', '/app/admin/users'),
                new AppMenuItem('Sectors', 'Pages.Sectors', '', '/app/main/Sector'),
                new AppMenuItem('Basins', 'Pages.Basin', '', '/app/main/basin'),
                // new AppMenuItem('CropsList', 'Pages.CropClasses', 'flaticon2-drop', '/app/main/farmplanning'),
                new AppMenuItem('FarmServices', 'Pages.FarmServ', '', '/app/main/FarmServices'),

            ], undefined, undefined, () => {
                return this._appSessionService.user.isSubscriptionActivated;
            }),

            new AppMenuItem('FarmServices', 'Pages.ServiceRequest', 'menu-icons Farm-Services-icon', '', [], [
                new AppMenuItem('servicerequests', 'Pages.ServiceRequest', '', '/app/main/ServiceRequests'),
                new AppMenuItem('DispenseHeader', 'Pages.IssuingOrder', '', '/app/main/issing-orders'),
         ], undefined, undefined, () => {
                return this._appSessionService.user.isSubscriptionActivated;
            }
            ),

            new AppMenuItem('Workers', 'Pages.Worker', 'menu-icons Workers-icon', '', [], [
                new AppMenuItem('permanentWorkers', 'Pages.Worker', '', '/app/main/workers'),
                // new AppMenuItem('Shifts', 'Pages.Worker', '', '/app/main/'),
                new AppMenuItem('Vacations', 'Pages.LeaveRequest', '', '/app/main/leaves'),
                new AppMenuItem('DailyWorkers', 'Pages.Worker', '', '/app/main/workers/daily-workers'),

                new AppMenuItem('contractors', 'Pages.Contractor', '', '/app/main/contractors')

            ], undefined, undefined, () => {
                return this._appSessionService.user.isSubscriptionActivated;
            }),



            // new AppMenuItem('contractors', '', 'menu-icons Labor-icon', '', [], [
            // ], undefined, undefined, () => {
            //     return this._appSessionService.user.isSubscriptionActivated;
            // }),


            new AppMenuItem('StoreManagement', 'Pages.Stores', 'menu-icons store-icon', '', [], [
                new AppMenuItem('Stores', 'Pages.Stores', '', '/app/main/storeManagement/store'),
                new AppMenuItem('StoreSectors', 'Pages.StoreSectors', '', '/app/main/storeManagement/storeSector'),
                new AppMenuItem('StoreTransferOrder', 'Pages.TransferOrders', '', '/app/main/storeManagement/transferOrder'),
                new AppMenuItem('StoreInventories', 'Pages.InventoryProcesses', '', '/app/main/storeManagement/inventory'),
                new AppMenuItem('ReturnRequests', 'Pages.ReturnRequests', '', '/app/main/storeManagement/returnRequests'),
            ], undefined, undefined, () => {
                return (this._appSessionService.user.isSubscriptionActivated);
            }),




            new AppMenuItem('PurchaseManagement', 'Pages.PurchaseManagement', 'menu-icons basket-shopping-icon', '', [], [
                new AppMenuItem('PurchaseRequests', 'Pages.PurchaseRequests', '', '/app/main/pr/purchaseRequests', undefined, undefined, undefined),
                new AppMenuItem('PurchaseOrder', 'Pages.PurchaseOrders', '', '/app/main/pr/purchaseRequests/purchase-orders', undefined, undefined, undefined),
                new AppMenuItem('Invoices', 'Pages.POInvoices', '', '/app/main/pr/PurchaseInvoice', undefined, undefined, undefined),
            ], undefined, undefined, () => {
                return (this._appSessionService.user.isSubscriptionActivated);
            }),



            new AppMenuItem('ConsultantPayments', '', 'menu-icons Salaries-icon', '', [], [
                new AppMenuItem('PermanentPayslips', '', '', '/app/main/accounting/internal-workers-salary-list'),
                new AppMenuItem('ExternalPayslips', '', '', '/app/main/accounting/external-workers-salary-list'),
                new AppMenuItem('VendorBalances', 'Pages.VendorBalances', '', '/app/main/pr/VendorBalances', undefined, undefined, undefined),

            ], undefined, undefined, () => {
                return this._appSessionService.user.isSubscriptionActivated;
            }),

            // new AppMenuItem('Requests', 'Pages.Requests', 'menu-icons Pages-Requests-icon', '/app/admin/requests', undefined, undefined, undefined, undefined, () => {
            //     return this._appSessionService.user.isSubscriptionActivated;
            // }, undefined, requestNumber == '0' ? undefined : requestNumber, 'badge-danger'),


            // new AppMenuItem('UserManagement', 'Pages.Useradministration', 'flaticon-users', '', [], [
            //     //  new AppMenuItem('Roles', '', 'flaticon-suitcase', '/app/admin/roles'),
            //     new AppMenuItem('Users', 'Pages.Users', '', '/app/admin/users'),
            // ], undefined, undefined, () => {
            //     return this._appSessionService.user.isSubscriptionActivated;
            // }
            // ),


            // new AppMenuItem('contractors', 'Pages.Contractor', 'menu-icons Labor-icon', '', [], [
            //     new AppMenuItem('maincontractors', 'Pages.Contractor', '', '/app/main/contractors')
            // ], undefined, undefined, () => {
            //     return this._appSessionService.user.isSubscriptionActivated;
            // }),

            // new AppMenuItem('servicerequests', 'Pages.ServiceRequest', 'flaticon-interface-8', '/app/main/ServiceRequests', [], [
            // ], undefined, undefined, () => {
            //     return this._appSessionService.user.isSubscriptionActivated;
            // }),


            // new AppMenuItem('SalaryAndPayslips', 'Pages.Payroll', 'menu-icons Salaries-icon', '', [], [
            //     new AppMenuItem('PermanentPayslips', 'Pages.Payroll', '', '/app/main/accounting/internal-workers-salary-list'),
            //     new AppMenuItem('ExternalPayslips', 'Pages.Payroll', '', '/app/main/accounting/external-workers-salary-list'),
            // ], undefined, undefined, () => {
            //     return this._appSessionService.user.isSubscriptionActivated;
            // }),
            // new AppMenuItem('Financial', 'Pages.Farmses', 'flaticon-notepad', '', [], [
            //     new AppMenuItem('FarmPurchaseLimit', '', 'flaticon-price-tag', '/app/main/farm-purchase-limit'),
            // ], undefined, undefined, () => {
            //     return (this._appSessionService.user.isSubscriptionActivated);
            // }),


            // new AppMenuItem('Reports', 'Pages.Tenant.Reports', 'flaticon-notes', '/app/main/reporting-center', undefined, undefined, undefined, undefined, () => {
            //     return this._appSessionService.user.isSubscriptionActivated;
            // }),

            new AppMenuItem('consultantsPersons', 'Pages.Consultancy', 'menu-icons Consultants-icon', '',
                [], [
                    new AppMenuItem('consultantsDetails', 'Pages.Consultant.View', '', '/app/main/consultancy/consultants'),
                new AppMenuItem('Invitations', 'Pages.ConsultantInvitation', '', '/app/main/consultancy/consultantInvitations'),
                new AppMenuItem('ConsultantVisits', '', '', '/app/main/consultant/consultantVisits'),
                new AppMenuItem('Contracts', 'Pages.Contract.View', '', '/app/main/consultancy/consultantContracts'),
            ], undefined, undefined, () => {
                return (this._appSessionService.user.isSubscriptionActivated);
            }),
        //     new AppMenuItem('AllRecommendations', 'Pages.Recommendations', 'menu-icons Recommendations-icon', '',
        //     [], [
        //     new AppMenuItem('RunningRecommendationsList', 'Pages.InprogressRecommendations', 'flaticon-list-1', '/app/main/consultant/RunningRecommendations'),
        //     new AppMenuItem('CompletedRecommendationsList', 'Pages.CompleteRecommendations', 'flaticon-interface-10', '/app/main/consultant/completedRecommendations'),
        // ], undefined, undefined, () => {
        //     return (this._appSessionService.user.isSubscriptionActivated);
        // }),
            // new AppMenuItem('ExecuteRecommendations', 'Pages.Processing', 'flaticon-interface-11', '', [], [
            //     new AppMenuItem('IrrigationAndFertilization', 'Pages.Processing', 'flaticon-interface-10', '', [], [
            //         new AppMenuItem('RunningRecommendationsList', 'Pages.IrrigationFertilization.Running', 'flaticon-list', '/app/main/Processing/RunningIrrigation'),
            //         new AppMenuItem('CompletedRecommendationsList', 'Pages.IrrigationFertilization.Complete', 'flaticon-interface-10', '/app/main/Processing/CompleteIrrigation'),
            //     ]),
            //     new AppMenuItem('Spraying', 'Pages.Processing', 'flaticon-rotate', '', [], [
            //         new AppMenuItem('RunningRecommendationsList', 'Pages.Spraying.Running', 'flaticon-list', '/app/main/Processing/RunningSpraying'),
            //         new AppMenuItem('CompletedRecommendationsList', 'Pages.Services.Complete', 'flaticon-interface-10', '/app/main/Processing/CompleteSpraying'),
            //     ]),
            //     new AppMenuItem('Services', 'Pages.Processing', 'flaticon-settings-1', '', [], [
            //         new AppMenuItem('RunningRecommendationsList', 'Pages.Services.Running', 'flaticon-list', '/app/main/Processing/RunningService'),
            //         new AppMenuItem('CompletedRecommendationsList', 'Pages.Services.Complete', 'flaticon-interface-10', '/app/main/Processing/CompleteService'),
            //     ]),
            // ], undefined, undefined, () => {
            //     return (this._appSessionService.user.isSubscriptionActivated);
            // }),
            // new AppMenuItem('InvitationsAndVisits', 'Consultant', 'flaticon-notepad', '', [], [
            //     new AppMenuItem('Invitations', '', 'flaticon-notes', '/app/main/consultant/invitations'),
            //     new AppMenuItem('ConsultantVisits', '', 'flaticon-users', '/app/main/consultant/consultantVisits'),
            //     new AppMenuItem('ConsultantPayments', '', 'flaticon-price-tag', '/app/main/consultant/consultantPayments'),
            // ], undefined, undefined, () => {
            //     return (!this._appSessionService.user.isSuperAdmin);
            // }),
            new AppMenuItem('Recommendations', 'Consultant', 'menu-icons Recommendations-icon', '', [], [
                new AppMenuItem('RunningRecommendationsList', '', '', '/app/main/consultant/RunningRecommendations'),
                new AppMenuItem('CompletedRecommendationsList', '', '', '/app/main/consultant/completedRecommendations'),
                new AppMenuItem('IrrigationAndFertilization', '', '', '/app/main/Processing/CompleteIrrigation'),
                new AppMenuItem('Spraying', '', '', '/app/main/Processing/CompleteSpraying'),
            ], undefined, undefined, () => {
                return (!this._appSessionService.user.isSuperAdmin);
            }),
            // new AppMenuItem('IrrigationAndFertilization', 'Consultant', 'flaticon-interface-11', '', [], [
            //     new AppMenuItem('CompletedRecommendationsList', '', 'flaticon-interface-10', '/app/main/Processing/CompleteIrrigation'),
            // ], undefined, undefined, () => {
            //     return (!this._appSessionService.user.isSuperAdmin);
            // }),
            // new AppMenuItem('Spraying', 'Consultant', 'flaticon-interface-11', '', [], [
            //     new AppMenuItem('CompletedRecommendationsList', '', 'flaticon-interface-10', '/app/main/Processing/CompleteSpraying'),
            // ], undefined, undefined, () => {
            //     return (!this._appSessionService.user.isSuperAdmin);
            // }),
            // new AppMenuItem('Services', 'Consultant', 'flaticon-settings', '', [], [
            //     new AppMenuItem('CompletedRecommendationsList', '', 'flaticon-interface-10', '/app/main/Processing/CompleteService'),
            // ], undefined, undefined, () => {
            //     return (!this._appSessionService.user.isSuperAdmin);
            // }),
            // new AppMenuItem('CompanyManagement', '', 'flaticon-interface-8', '', [], [
            //     new AppMenuItem('Farms', '', 'flaticon-suitcase', '/app/main/farm',
            //         undefined, undefined, undefined, () => {
            //             return (this._appSessionService.user.isSubscriptionActivated &&
            //                 this._permissionCheckerService.isGranted('Pages.Farmses'));
            //         }),
            //     new AppMenuItem('CompanyProfile', '', 'flaticon-suitcase', '/app/admin/company',
            //         undefined, undefined, undefined, () => {
            //             return (this._appSessionService.user.isSubscriptionActivated &&
            //                 this._permissionCheckerService.isGranted('Pages.Company'))
            //         }),
            //     new AppMenuItem(
            //         'Subscription',
            //         'Pages.SubscriptionManagement',
            //         'flaticon-refresh',
            //         '/app/admin/subscription-management'
            //     )
            // ], undefined, undefined, () => {
            //     return !this._appSessionService.user.isSuperAdmin &&
            //         this._permissionCheckerService.isGranted('Pages.CompanyManagement')
            // }
            // ),


            ///Super Admin Pages
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'chart-histogram',
                '/app/admin/hostDashboard',
                undefined, undefined, undefined, () => {
                    //return this._appSessionService.user.isSubscriptionActivated;
                }
            ),
            new AppMenuItem('LookUps', 'Pages.Administration.Editions', 'flaticon-list-3', '/app/main/lookUp/AcceptedValueCategory'),
            new AppMenuItem('Companys', 'Pages.Administration.Tenants', 'flaticon-list-3', '/app/admin/tenants'),


            new AppMenuItem('Editions', 'Pages.Administration.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem(
                'Administration',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    // new AppMenuItem(
                    //     'OrganizationUnits',
                    //     'Pages.Administration.OrganizationUnits',
                    //     'flaticon-map',
                    //     '/app/admin/organization-units', undefined, undefined, undefined, undefined, () => {
                    //         return (this._appSessionService.user.isSuperAdmin);
                    //     }
                    // ),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'Roles',
                        'Pages.Administration.Roles',
                        'flaticon-tabs',
                        '/app/admin/adminRoles'
                    ),
                    new AppMenuItem(
                        'Users',
                        'Pages.Users',
                        'flaticon-tabs',
                        '/app/admin/users-host'
                    ),
                    // new AppMenuItem(
                    //     'AuditLogs',
                    //     'Pages.Administration.AuditLogs',
                    //     'flaticon-folder-1',
                    //     '/app/admin/auditLogs'
                    // ),
                    // new AppMenuItem(
                    //     'Maintenance',
                    //     'Pages.Administration.Host.Maintenance',
                    //     'flaticon-lock',
                    //     '/app/admin/maintenance'
                    // ),
                    // new AppMenuItem(
                    //     'VisualSettings',
                    //     'Pages.Administration.UiCustomization',
                    //     'flaticon-medical',
                    //     '/app/admin/ui-customization'
                    // ),
                    // new AppMenuItem(
                    //     'WebhookSubscriptions',
                    //     'Pages.Administration.WebhookSubscription',
                    //     'flaticon2-world',
                    //     '/app/admin/webhook-subscriptions'
                    // ),
                    // new AppMenuItem(
                    //     'DynamicProperties',
                    //     'Pages.Administration.DynamicProperties',
                    //     'flaticon-interface-8',
                    //     '/app/admin/dynamic-property'
                    // ),
                    // new AppMenuItem(
                    //     'Settings',
                    //     'Pages.Administration.Host.Settings',
                    //     'flaticon-settings',
                    //     '/app/admin/hostSettings'
                    // ),
                    // new AppMenuItem(
                    //     'Settings',
                    //     'Pages.Administration.Tenant.Settings',
                    //     'flaticon-settings',
                    //     '/app/admin/tenantSettings', undefined, undefined, undefined, undefined, () => {
                    //         return (this._appSessionService.user.isSuperAdmin);
                    //     }
                    // ),
                ],
                undefined, undefined, () => {
                    return (this._appSessionService.user.isSuperAdmin);
                }
            ),

        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
    GetAllAcceptedValuesCategory(): AppMenuItem[] {
        let menueItems: AppMenuItem[];
        menueItems = [];
        this._AcceptedValueCategoryServiceProxy.getAcceptedValueCategoriesEditable().subscribe(x => {
            this.categoriesData = x;
            this.categoriesData.forEach(item => {
                console.log(item);
                menueItems.push(new AppMenuItem(item.name, '', 'flaticon-shapes', '/app/main/lookUp/UseracceptedValues', [], [], false, { id: item.id }))
            });

        }, error => {
            console.log(error);
        });
        return menueItems;
        // let AcceptedValuesLst = new AppMenuItem[];

    }
}
