<div appBsModal #mySettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (submit)="save()">
                <div class="modal_header">
                    {{ 'MySettings' | localize }}
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5.66689 4.2374C5.27926 3.85966 4.65803 3.86725 4.27934 4.25436C3.90065 4.64146 3.9079 5.2615 4.29554 5.63924L10.6968 11.8771L4.29554 18.1149C3.9079 18.4927 3.90065 19.1127 4.27934 19.4998C4.65803 19.8869 5.27926 19.8945 5.66689 19.5168L12.1018 13.2462L18.3331 19.3184C18.7207 19.6961 19.342 19.6885 19.7207 19.3014C20.0993 18.9143 20.0921 18.2943 19.7045 17.9166L13.5067 11.8771L19.7045 5.83758C20.0921 5.45985 20.0993 4.83981 19.7207 4.45271C19.342 4.0656 18.7207 4.058 18.3331 4.43574L12.1018 10.508L5.66689 4.2374Z"
                                fill="#7C7B7B" />
                        </svg>
                    </button>
                </div>
                <div class="d-flex align-items-center profile_head">
                    <div class="photo_wrap">
                        <img *ngIf="!imageChangedEvent" src="{{profilePicture}}" class="img-thumbnail img-rounded mb-1"/>
                        <image-cropper *ngIf="imageChangedEvent" [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="132" format="png"
                            class="img-thumbnail img-rounded mb-1" (imageCropped)="imageCroppedFile($event)"
                            [hidden]="!useGravatarProfilePicture">
                        </image-cropper>
                        <div class="form-group">
                            <div class="input-group">
                                <div class="custom-file d-none">
                                    <input #FileCustom type="file" id="uploadProfilePicture" accept="image/*"
                                        (change)="fileChangeEvent($event)" [disabled]="!useGravatarProfilePicture">
                                    <label class="custom-file-label" #uploadProfilePictureInputLabel
                                        for="uploadProfilePicture">
                                        {{"ChooseAFile" | localize}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="upload_btns">
                            <button class="border-0 p-0 bg-transparent" *ngIf="imageChangedEvent"
                                (click)="saveCroppedPicture($event)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="
                                http://www.w3.org/2000/svg">
                                <path d="M22.1705 5.73488L17.5151 1.07949C17.4109 0.974697 17.287 0.891618 17.1504 0.835062C17.0139 0.778505 16.8675 0.749595 16.7197 0.750004H2.625C2.32674 0.750352 2.04079 0.86899 1.82989 1.07989C1.61899 1.2908 1.50035 1.57674 1.5 1.875V22.125C1.50035 22.4233 1.61899 22.7092 1.82989 22.9201C2.04079 23.131 2.32674 23.2497 2.625 23.25H21.375C21.6733 23.2497 21.9592 23.131 22.1701 22.9201C22.381 22.7092 22.4997 22.4233 22.5 22.125V6.5303C22.5004 6.38252 22.4715 6.23612 22.4149 6.09958C22.3584 5.96305 22.2753 5.83909 22.1705 5.73488ZM15 2.25V6.75H8.25V2.25H15ZM21 21.75H3V2.25H6.75V8.25H16.5V2.25H16.5644L21 6.68565V21.75Z" fill="#4EAEAF"/>
                                <path d="M11.8125 10.5C10.9596 10.5 10.1258 10.7529 9.41661 11.2268C8.70742 11.7007 8.15467 12.3742 7.82827 13.1622C7.50187 13.9502 7.41647 14.8173 7.58287 15.6538C7.74926 16.4904 8.15999 17.2588 8.7631 17.8619C9.36622 18.465 10.1346 18.8757 10.9712 19.0421C11.8077 19.2085 12.6748 19.1231 13.4628 18.7967C14.2508 18.4703 14.9244 17.9176 15.3982 17.2084C15.8721 16.4992 16.125 15.6654 16.125 14.8125C16.1238 13.6691 15.669 12.573 14.8605 11.7645C14.052 10.956 12.9559 10.5012 11.8125 10.5ZM11.8125 17.625C11.2562 17.625 10.7125 17.4601 10.25 17.151C9.78745 16.842 9.42696 16.4027 9.21409 15.8888C9.00122 15.3749 8.94552 14.8094 9.05404 14.2638C9.16256 13.7182 9.43043 13.2171 9.82376 12.8238C10.2171 12.4304 10.7182 12.1626 11.2638 12.054C11.8094 11.9455 12.3749 12.0012 12.8888 12.2141C13.4027 12.427 13.842 12.7874 14.151 13.25C14.4601 13.7125 14.625 14.2562 14.625 14.8125C14.6242 15.5582 14.3276 16.273 13.8003 16.8003C13.273 17.3276 12.5582 17.6242 11.8125 17.625Z" fill="#4EAEAF"/>
                                </svg>
                                
                            </button>
                            <button class="border-0 p-0 bg-transparent" (click)="openFileInput($event)">
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19.9165 0.904171C18.7068 -0.303344 16.7479 -0.303344 15.5383 0.904171L14.4423 2.00612L2.77609 13.6662L2.7513 13.6912C2.7453 13.6972 2.7453 13.7036 2.7389 13.7036C2.72651 13.7222 2.70791 13.7406 2.69571 13.7591C2.69571 13.7653 2.68932 13.7653 2.68932 13.7715C2.67692 13.7901 2.67092 13.8025 2.65833 13.8211C2.65232 13.8273 2.65232 13.8333 2.64612 13.8397C2.63993 13.8583 2.63373 13.8707 2.62733 13.8893C2.62733 13.8953 2.62133 13.8953 2.62133 13.9017L0.0329398 21.6853C-0.0429899 21.9069 0.0147322 22.1523 0.181506 22.3167C0.298694 22.4324 0.456752 22.4971 0.621202 22.4965C0.688415 22.4953 0.755047 22.4849 0.819355 22.4655L8.59692 19.8709C8.60293 19.8709 8.60293 19.8709 8.60932 19.8649C8.62888 19.8591 8.64767 19.8508 8.66491 19.8399C8.66975 19.8393 8.67401 19.8372 8.6775 19.8339C8.6959 19.8215 8.7207 19.8089 8.73929 19.7965C8.75769 19.7843 8.77648 19.7657 8.79508 19.7533C8.80127 19.7469 8.80728 19.7469 8.80728 19.7409C8.81367 19.7347 8.82607 19.7287 8.83226 19.7161L21.5945 6.95395C22.802 5.7443 22.802 3.78543 21.5945 2.57598L19.9165 0.904171ZM8.39877 18.4157L4.08898 14.1061L14.8758 3.3192L19.1856 7.62879L8.39877 18.4157ZM3.48193 15.2516L7.24704 19.0165L1.59337 20.8989L3.48193 15.2516ZM20.7215 6.08696L20.065 6.7496L15.755 2.43962L16.4179 1.77717C17.1429 1.05274 18.3181 1.05274 19.0433 1.77717L20.7275 3.46138C21.4471 4.18968 21.4443 5.36214 20.7215 6.08696Z"
                                        fill="#4EAEAF" />
                                </svg>
                            </button>
                            <button class="border-0 p-0 bg-transparent" (click)="DeleteImage()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.15918 22.25H15.8392C16.6076 22.2389 17.3434 21.9381 17.8996 21.4079C18.4558 20.8776 18.7914 20.157 18.8392 19.39L19.7492 5.55C19.7538 5.44966 19.7386 5.34938 19.7042 5.25498C19.6699 5.16057 19.6172 5.07391 19.5492 5C19.4791 4.9234 19.3942 4.86177 19.2997 4.81881C19.2052 4.77584 19.103 4.75244 18.9992 4.75H4.99918C4.89633 4.74977 4.79454 4.7707 4.70013 4.81148C4.60571 4.85226 4.52069 4.91202 4.45036 4.98704C4.38002 5.06207 4.32586 5.15077 4.29125 5.24761C4.25664 5.34446 4.24232 5.44739 4.24918 5.55L5.11918 19.39C5.16748 20.1638 5.50865 20.8902 6.07335 21.4214C6.63804 21.9527 7.38386 22.249 8.15918 22.25ZM18.1592 6.25L17.3892 19.3C17.3637 19.6935 17.1893 20.0625 16.9013 20.3319C16.6133 20.6013 16.2335 20.7508 15.8392 20.75H8.15918C7.76554 20.7485 7.38704 20.5982 7.09957 20.3292C6.81211 20.0603 6.63693 19.6927 6.60918 19.3L5.79918 6.3L18.1592 6.25Z"
                                        fill="#4EAEAF" />
                                    <path
                                        d="M3 6.25H21C21.1989 6.25 21.3897 6.17098 21.5303 6.03033C21.671 5.88968 21.75 5.69891 21.75 5.5C21.75 5.30109 21.671 5.11032 21.5303 4.96967C21.3897 4.82902 21.1989 4.75 21 4.75H3C2.80109 4.75 2.61032 4.82902 2.46967 4.96967C2.32902 5.11032 2.25 5.30109 2.25 5.5C2.25 5.69891 2.32902 5.88968 2.46967 6.03033C2.61032 6.17098 2.80109 6.25 3 6.25Z"
                                        fill="#4EAEAF" />
                                    <path
                                        d="M9 6.25H15C15.1981 6.24741 15.3874 6.16756 15.5275 6.02747C15.6676 5.88737 15.7474 5.69811 15.75 5.5V3.7C15.7373 3.18682 15.5278 2.69816 15.1648 2.33518C14.8018 1.97219 14.3132 1.76268 13.8 1.75H10.2C9.67828 1.76305 9.18233 1.97951 8.818 2.35317C8.45367 2.72684 8.24984 3.22812 8.25 3.75V5.5C8.25259 5.69811 8.33244 5.88737 8.47253 6.02747C8.61263 6.16756 8.80189 6.24741 9 6.25ZM14.25 4.75H9.75V3.75C9.75 3.63065 9.79741 3.51619 9.8818 3.4318C9.96619 3.34741 10.0807 3.3 10.2 3.3H13.8C13.9193 3.3 14.0338 3.34741 14.1182 3.4318C14.2026 3.51619 14.25 3.63065 14.25 3.75V4.75Z"
                                        fill="#4EAEAF" />
                                    <path
                                        d="M9 18.25C9.19811 18.2474 9.38737 18.1676 9.52747 18.0275C9.66756 17.8874 9.74741 17.6981 9.75 17.5V9.5C9.75 9.30109 9.67098 9.11032 9.53033 8.96967C9.38968 8.82902 9.19891 8.75 9 8.75C8.80109 8.75 8.61032 8.82902 8.46967 8.96967C8.32902 9.11032 8.25 9.30109 8.25 9.5V17.5C8.25259 17.6981 8.33244 17.8874 8.47253 18.0275C8.61263 18.1676 8.80189 18.2474 9 18.25Z"
                                        fill="#4EAEAF" />
                                    <path
                                        d="M15 18.25C15.1981 18.2474 15.3874 18.1676 15.5275 18.0275C15.6676 17.8874 15.7474 17.6981 15.75 17.5V9.5C15.75 9.30109 15.671 9.11032 15.5303 8.96967C15.3897 8.82902 15.1989 8.75 15 8.75C14.8011 8.75 14.6103 8.82902 14.4697 8.96967C14.329 9.11032 14.25 9.30109 14.25 9.5V17.5C14.2526 17.6981 14.3324 17.8874 14.4725 18.0275C14.6126 18.1676 14.8019 18.2474 15 18.25Z"
                                        fill="#4EAEAF" />
                                    <path
                                        d="M12 18.25C12.1981 18.2474 12.3874 18.1676 12.5275 18.0275C12.6676 17.8874 12.7474 17.6981 12.75 17.5V9.5C12.75 9.30109 12.671 9.11032 12.5303 8.96967C12.3897 8.82902 12.1989 8.75 12 8.75C11.8011 8.75 11.6103 8.82902 11.4697 8.96967C11.329 9.11032 11.25 9.30109 11.25 9.5V17.5C11.2526 17.6981 11.3324 17.8874 11.4725 18.0275C11.6126 18.1676 11.8019 18.2474 12 18.25Z"
                                        fill="#4EAEAF" />
                                </svg>
                            </button>
                        </div>

                    </div>
                    <ul class="user_info">
                        <li>
                            <span>اسم المستخدم</span>
                            {{user.userName? user.userName : ('NoItemsFound' | localize)}}
                        </li>
                        <li>
                            <span>الوظيفة</span>
                            {{user.role ? (user.role | localize) : ('NoItemsFound' | localize)}}
                        </li>
                        <li *ngIf="user.surname !== 'Owner' && user.surname !== 'Consultant'">
                            <span>الراتب</span>
                            {{user.salary ? user.salary : ('NoItemsFound' | localize)}}
                        </li>
                    </ul>
                </div>
                <div class="modal-body" *ngIf="user">
                    <tabset *ngIf="false">
                        <tab heading="{{ 'Profile' | localize }}"></tab>
                        <tab *ngIf="!isMultiTenancyEnabled || isTwoFactorLoginEnabledForApplication"
                            heading="{{ 'TwoFactorLogin' | localize }}">
                            <div>
                                <h4>Google Authenticator</h4>
                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <div>
                                        {{ 'ScanQrCodeWithYourMobileApp' | localize }}
                                    </div>
                                    <div class="text-center">
                                        <img [src]="user.qrCodeSetupImageUrl" />
                                    </div>
                                    <small>
                                        {{ 'GoogleAuthenticatorReferrerLink' | localize }}:
                                        <a href="https://support.google.com/accounts/answer/1066447" target="_blank"
                                            rel="noopener noreferrer">
                                            Google Authenticator
                                        </a>
                                    </small>
                                </div>
                                <div [hidden]="isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn btn-success" (click)="updateQrCodeSetupImageUrl()">
                                        {{ 'Enable' | localize }}
                                    </button>
                                </div>
                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn btn-danger" (click)="disableGoogleAuthenticator()">
                                        {{ 'Disable' | localize }}
                                    </button>
                                </div>
                            </div>
                        </tab>
                    </tabset>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Name">{{ 'FirstName' | localize }} <sup>*</sup></label>
                                <input id="Name" #nameInput="ngModel" class="form-control" type="text"
                                    (keypress)="omit_special_char($event)" name="Name" [(ngModel)]="user.name" required
                                    maxlength="64" />
                                <!-- <validation-messages [formCtrl]="nameInput"></validation-messages> -->
                                <span class="text-danger"
                                *ngIf=" !nameInput.valid">
                                {{l('ThisFieldIsRequired')}}
                            </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Surname">{{ 'Surname' | localize }} <sup>*</sup></label>
                                <input id="Surname" #surnameInput="ngModel" type="text" name="Surname"
                                    (keypress)="omit_special_char($event)" class="form-control"  
                                    [(ngModel)]="user.surname" required maxlength="64" />
                                    <span class="text-danger"
                                    *ngIf=" !surnameInput.valid">
                                    {{l('ThisFieldIsRequired')}}
                                </span>
                                </div>
                        </div>
                        <div class="col-md-6" *ngIf="ShowEmailButton">
                            <div class="form-group">
                                <label for="EmailAddress">{{ 'EmailAddress' | localize }} *</label>
                                <input id="EmailAddress" #emailAddressInput="ngModel" type="email" disabled
                                    name="EmailAddress" class="form-control" [(ngModel)]="user.emailAddress" required
                                    email maxlength="256" />
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'PhoneNumber' | localize }}</label>
                                <div class="input-group">
                                    <input type="tel" name="PhoneNumber" class="form-control"
                                        (input)="verifyPhonenumber($event)" #phoneNumberInput 
                                        pattern="^(\+201|01)[0-2,5]{1}[0-9]{8}$" [(ngModel)]="user.phoneNumber"
                                        maxlength="13" />
                                    <span *ngIf="smsEnabled" id="SpanSmsVerificationUnverified"
                                        class="input-group-append">
                                        <button id="btnSmsVerification" (click)="smsVerify()" [disabled]="
                                            isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber
                                        " class="btn btn-primary" type="button"
                                            tooltip="{{ 'YourPhoneNumberIsNotVerified' | localize }}">
                                            <i class="fa fa-exclamation-triangle"></i>
                                            {{ 'Verify' | localize }}
                                        </button>
                                    </span>

                                    <div *ngIf="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber"
                                        class="input-group-append" id="SpanSmsVerificationVerified">
                                        <span class="input-group-text"
                                            tooltip="{{ 'YourPhoneNumberIsVerified' | localize }}">
                                            <i class="la la-check label-success" [attr.aria-label]="l('Verified')"></i>
                                        </span>
                                    </div>
                                    <p class="w-100 pn_error" *ngIf="!validPhoneNumber">{{'InvalidPhoneNumber' | localize}}</p>
                                </div>
                                <span class="la la-check label-success" *ngIf="smsVerified &&smsEnabled">
                                    {{ 'YourPhoneNumberIsVerified' | localize }}</span>
                                <span class="la la-check label-success"
                                    *ngIf="!smsVerified &&smsEnabled&&changePassword">
                                    {{ 'YourPhoneNumberIsNotVerified' | localize }}</span>
                                    <validation-messages [formCtrl]="phoneNumberInput"></validation-messages>

                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="user.surname !== 'Owner' && user.surname !== 'Consultant'">
                            <div class="form-group">
                                <label for="SocialStatus">{{ 'SocialStatus' | localize }} </label>
                                <select class="form-control pt-0 pb-0" name="SocialStatus" id="SocialStatus"
                                    [(ngModel)]="user.socialStatusId" #SocialStatusInput="ngModel">
                                    <option value="null" disabled selected>{{l("unknown")}}
                                    </option>
                                    <option *ngFor="let r of SocialStatusDropDown"
                                        [attr.selected]="user.socialStatusId ==r.code ? 'selected' : null"
                                        [value]="r.code">
                                        {{r.name}}</option>
                                </select>

                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="showTimezoneSelection">
                            <div class="form-group">
                                <label for="Timezone">{{ 'Timezone' | localize }}</label>
                                <timezone-combo name="Timezone" [(ngModel)]="user.timezone"
                                    defaultTimezoneScope="{{ defaultTimezoneScope }}"></timezone-combo>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="user.surname !== 'Owner' && user.surname !== 'Consultant'">
                            <div class="form-group">
                                <label for="Address">{{ 'Address' | localize }} </label>
                                <input id="Address" #JobInput="ngModel" type="text" class="form-control"
                                    [(ngModel)]="user.address" name="Address" maxlength="256" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end ">
                        <button type="button" class="action_btn action_btn--border" (click)="close()">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="submit" class="action_btn"
                            [disabled]="!mySettingsModalForm.form.valid || !validPhoneNumber ||(!smsVerified&&changePassword)">
                            <span>{{ 'Save' | localize }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div appBsModal #smsVerificationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #smsVerificationModalForm="ngForm" (ngSubmit)="saveCode()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'VerifyYourCode' | localize }}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="closeModal()">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="inputDiv" class="form-group">
                        <label for="YourCode">{{ 'YourCode' | localize }} *</label>
                        <input id="YourCode" #codeInput="ngModel" class="form-control" autoFocus type="text" name="Code"
                            [(ngModel)]="code" required maxlength="6" minlength="6" />
                        <validation-messages [formCtrl]="codeInput"></validation-messages>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary"
                        [disabled]="!smsVerificationModalForm.form.valid || saving">
                        <i class="fa fa-save"></i>
                        <span>{{ 'Verify' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>