import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';
import { acceptedValueCategoriesEnum } from '@app/shared/Enum/AcceptedValueCategoriesEnum';
import { PageModeEnum } from '@app/shared/Enum/PageModeEnum';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ItemsServiceProxy, AcceptedValuesServiceProxy, AcceptedValueDto, CreateOrEditItemDto } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CreateOrEditmanifactursModalComponent } from '../../create-or-edit-manifacturs-modal/create-or-edit-manifacturs-modal.component';

@Component({
  selector: 'app-create-edit-item-template',
  templateUrl: './create-edit-item-template.component.html',
  styleUrls: ['./create-edit-item-template.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class CreateEditItemTemplateComponent extends AppComponentBase implements OnInit, OnChanges {
  @Input() itemClassId: number;
  @Input() itemCategoryId: number;
  active = false;
  saving = false;
  item: CreateOrEditItemDto = new CreateOrEditItemDto();
  PageMode = PageModeEnum
  breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(this.l("Items"), "/app/main/lookUp/items"),
  ];
  acceptedValueCategories = acceptedValueCategoriesEnum;
  ItemsCategory: AcceptedValueDto[];
  Units: AcceptedValueDto[];
  Itemsclasses: AcceptedValueDto[];
  Manifacturs: AcceptedValueDto[];
  Type: any;
  itemId;
  ItemForm: FormGroup;
  constructor(injector: Injector,
    private _activatedRoute: ActivatedRoute,
    private _itemsServiceProxy: ItemsServiceProxy,
    private fb: FormBuilder,
    private _AcceptedValuesService: AcceptedValuesServiceProxy) {
    super(injector);
    this.Type = this._activatedRoute.snapshot.queryParams['type']
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("itemClassId", this.itemClassId)
    console.log("itemCategoryId", this.itemCategoryId)
    if (this.itemClassId != undefined) {
      this.form.itemClass.setValue(this.itemClassId);
      this.form.itemClass.disable();
      this.getAllItemsCategory(this.itemClassId)
    }
    if (this.itemCategoryId != undefined) {
      this.form.itemCategory.setValue(this.itemCategoryId);
      // this.form.itemCategory.disable();
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.Type = this._activatedRoute.snapshot.queryParams['type']
    this.getAllItemMeasurmentUnitsDropDown();
    this.getAllManifacturs();
    this.getAllItemsclasses();
  }
  initForm() {
    this.ItemForm = this.fb.group({
      name: ['', Validators.required],
      nameEn: [''],
      itemClass: [null, Validators.required],
      itemCategory: [null, Validators.required],
      manifacturId: [null],
      packageQnty: [0, [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
      measureUnit: [null, Validators.required],
      link: [''],
      minStockLevel: [null,[Validators.required]],
      description: ['']
    });
  }
  id: number
  show(itemId?: number): void {
    this.id = itemId
    console.log("ITEMID==>", itemId)
    if (!itemId) {
      this.item = new CreateOrEditItemDto();
      this.item.id = itemId;
      if (this.itemClassId != undefined) {
        this.form.itemClass.setValue(this.itemClassId);
        this.getAllItemsCategory(this.itemClassId)
      }
      if (this.itemCategoryId != undefined) {
        // this.form.itemCategory.disable();
        this.form.itemCategory.setValue(this.itemCategoryId);
      }
      this.active = true;
    } else {
      this.itemId = itemId;
      this._itemsServiceProxy.getItemForEdit(itemId).subscribe(result => {
        console.log("RESULT===>", result)
        this.form.name.setValue(result.item.name);
        this.form.nameEn.setValue(result.item.nameEn);
        this.form.itemClass.setValue(result.item.itemClassId);
        this.getAllItemsCategory(result.item.itemClassId, result.item.itemCategoryId)
        this.form.manifacturId.setValue(result.item.manifacturId);
        this.form.packageQnty.setValue(result.item.unitsNumberInPackage);
        this.form.measureUnit.setValue(result.item.unitId);
        this.form.link.setValue(result.item.link);
        this.form.minStockLevel.setValue(result.item.minStockLevel);
        this.form.description.setValue(result.item.description);
        this.active = true;
      });
    }


  }
  get form() {
    return this.ItemForm.controls;
  }
  save() {
    if (this.ItemForm.valid) {
      this.saving = true;
      if (this.itemId != undefined) {
        this.item.id = this.itemId;
      }
      this.item.name = this.form.name.value;
      this.item.nameEn = this.form.nameEn.value;
      this.item.itemClassId = this.form.itemClass.value;
      this.item.itemCategoryId = this.form.itemCategory.value;
      this.item.manifacturId = this.form.manifacturId.value;
      this.item.unitsNumberInPackage = this.form.packageQnty.value;
      this.item.unitId = this.form.measureUnit.value;
      this.item.link = this.form.link.value;
      this.item.minStockLevel = this.form.minStockLevel.value;
      this.item.description = this.form.description.value;
      if (this.id == undefined || this.id == null) {
        this.item.id = null;
      }
      return this._itemsServiceProxy.createOrEdit(this.item);
      // .pipe(finalize(() => {
      //     this.saving = false;
      // }))
      // .subscribe(x => {
      //     this.saving = false;
      //     this.notify.info(this.l('SavedSuccessfully'));
      //     this._router.navigate(['/app/main/lookUp/items']);
      // })
    } else {
      Swal.fire(this.l('ErrorOccured'), this.l('PleaseFillAllRequiredFields'), 'error')


    }
  }


  getAllItemMeasurmentUnitsDropDown() {
    this._AcceptedValuesService.getAllItemUnits().subscribe(x => {
      this.Units = x;
    }, error => {
      console.log(error);
    })
  }
  getAllItemsclasses() {
    this._AcceptedValuesService.getAllItemsclasses().subscribe(x => {
      this.Itemsclasses = x;
    }, error => {
      console.log(error);
    })
  }
  getAllItemsCategory(id, itemId?: number) {
    this._AcceptedValuesService.getAllItemscategories(id).subscribe(x => {
      this.ItemsCategory = x;
      if (itemId != undefined) {
        this.form.itemCategory.setValue(itemId);
      }
    }, error => {
      console.log(error);
    })
  }
  getAllManifacturs() {
    this._AcceptedValuesService.getAllManifacturs().subscribe(x => {
      this.Manifacturs = x;
    }, error => {
      console.log(error);
    })
  }
  @ViewChild('ManifacturModal', { static: true }) ManifacturModal: CreateOrEditmanifactursModalComponent;
  showManifacturModal(PageMode) {
    if (PageMode == this.PageMode.Add) {
      this.ManifacturModal.show();
    } else {
      if (this.form.manifacturId.value != undefined)
        this.ManifacturModal.show(this.form.manifacturId.value);
      else
        this.ManifacturModal.show();
    }
  }

  get IsManifacturAddedByCompany() {
    return this.Manifacturs?.find(e => e.id == this.form.manifacturId.value && e.tenantId != null) != undefined;
  }
}
