export enum acceptedValueCategoriesEnum {
    SocialStatus = 1,
    //2,
    RequestStatus = 3,
    RequestType = 4,
    BasinMeasurementUnit = 5,
    RequestCategory = 6,
    FarmServiceExcutedBy = 7,
    FarmServiceExecutionUnit = 8,
    ExtendRecommendationDateStatus = 9,
    PurchaseOrderStatus = 10,
    ItemUnit = 11,
    PurchasePricingApprovalType = 12,
    StoreType = 13,
    StoreOrderType = 14,
    StoreOrderStatus = 15,
    FinancialOrderType = 16,
    FinancialOrderStatus = 17,
    LargeWeight = 18,
    SmallWeight = 19,
    Length = 20,
    Units = 21,
    Liter = 22,
    StoreTransferOrderStatus = 23,
    InventoryType = 24,
    ItemStatus = 25,
    PacketStatus = 26,
    DisposeStatus = 27,

    WaterUnit = 28,
    RecommendationCardType = 29,
    RecommendationStatus = 30,
    RecommendationType = 31,
    ItemCategoryType = 32,
    ExtendRecommendationDateType = 33,
    Crops = 34,
    Itemsclasses = 35,
    Itemscategories = 36,
    Vendors = 37,
    Items = 38,
    FarmServices = 39,
    CropClasses = 40,
    Manifactur = 41,

}