<div [class]="footerStyle" id="kt_footer">
    <div [class]="containerClass + ' d-flex flex-column flex-md-row align-items-center justify-content-center'">
        <div class="theme-text-dark-75 text-center order-2 order-md-1">
           <!--
            <span class="text-muted font-weight-bold mr-2">
                Arabdt
                <span *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                    {{ appSession.tenant.edition.displayName }}
                </span>
                | API: v{{ appSession.application.version }} | Client: v{{ webAppGuiVersion }} [{{ releaseDate }}]
            </span>
        --> 
{{'copyRights'| localize}} {{thisYear}}
        </div>
    </div>
</div>
