import { Component, EventEmitter, Injector, Output, ViewChild, OnInit, ElementRef } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CurrentUserProfileEditDto,
    SettingScopes,
    ProfileServiceProxy,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,
    UserServiceProxy,
    AccountServiceProxy,
    AcceptedValuesServiceProxy,
    AcceptedValueDto,
    UserLoginInfoDto,
    UpdateProfilePictureInput,
} from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SmsVerificationModalComponent } from '../sms-verification-modal/sms-verification-modal.component';
import { finalize } from 'rxjs/operators';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { IAjaxResponse, TokenService } from 'abp-ng2-module';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';



@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html',
    styleUrls: ['./my-settings-modal.component.scss'],
})
export class MySettingsModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('FileCustom') fileCustomInput: ElementRef<HTMLInputElement>;
    @ViewChild('smsVerificationModal', { static: true }) smsmodal: ModalDirective;
    @ViewChild('mySettingsModal', { static: true }) modal: ModalDirective;
    @ViewChild('smsVerificationModal') smsVerificationModal: SmsVerificationModalComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('uploadProfilePictureInputLabel') uploadProfilePictureInputLabel: ElementRef;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.UnitedKingdom];
    mobileNumber: any;
    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public smsEnabled: boolean;
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    private _initialTimezone: string = undefined;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    isTwoFactorLoginEnabledForApplication = false;
    CurrentRole: any;
    codeForNumber: any;
    code: any;
    SocialStatusDropDown: AcceptedValueDto[];
    ShowMemberFields: boolean = false;
    ImgEvent: any;
    fileToken: string | undefined;

    constructor(injector: Injector, private _profileService: ProfileServiceProxy,
        private _AccountServiceProxy: AccountServiceProxy,
        private _AcceptedValuesService: AcceptedValuesServiceProxy,
        private _userServiceProxy: UserServiceProxy,
        private _tokenService: TokenService,

    ) {
        super(injector);
    }
    currentUser: UserLoginInfoDto = this.appSession.user;

    ngOnInit(): void {
        this.getProfilePicture(this.currentUser.id);
        this.GetCurrentRoles();
        this.getAllSocialStatus();
        // this.initFileUploader()
        this.isTwoFactorLoginEnabledForApplication = abp.setting.getBoolean(
            'Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled'
        );
    }

    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.smsEnabled = this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
            if (!this.smsEnabled) {
                this.smsVerified = true
            }
            this.user = result;
            this._initialTimezone = result.timezone;
            this.canChangeUserName = this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
            this.modal.show();
            this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            this.savedPhoneNumber = result.phoneNumber;
            this.getProfilePicture(this.currentUser.id);
        });
    }

    updateQrCodeSetupImageUrl(): void {
        this._profileService.updateGoogleAuthenticatorKey().subscribe((result: UpdateGoogleAuthenticatorKeyOutput) => {
            this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            this.isGoogleAuthenticatorEnabled = true;
        });
    }

    disableGoogleAuthenticator(): void {
        this._profileService.disableGoogleAuthenticator().subscribe(() => {
            this.isGoogleAuthenticatorEnabled = false;
        });
    }

    smsVerify(): void {
        let input = new SendVerificationSmsInputDto();
        input.phoneNumber = this.user.phoneNumber;
        this._AccountServiceProxy.sendVerficationForPhoneNumber(input.phoneNumber).subscribe(res => {
            this.codeForNumber = res;
            this.smsmodal.show();
        });
        // this._profileService.sendVerificationSms(input).subscribe(() => {
        //     this.smsVerificationModal.show();
        // });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.user.phoneNumber;
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    close(): void {
        this.imageChangedEvent = '';
        // this.uploader.clearQueue();
        this.active = false;
        this.modal.hide();
    }

    openFileInput(event: any){
        event.preventDefault();
        this.fileCustomInput.nativeElement.click()
    }

    saveCroppedPicture(event: any){
        event.preventDefault();
        console.log(event);
        this.updateProfilePictureToUseGravatar(event);
    }

    save(): void {
        this.saving = true;
        let number: any = this.user.phoneNumber;
        if (this.user.name?.trim() == "" || this.user.userName?.trim() == "" || this.user.surname?.trim() == "" || number?.trim() == "") {
            // this.saving = false;
            this.message.warn(this.l('PleaseEnterValidValues'));
            return
        }
        this.user.phoneNumber = number;
        this.spinnerService.show()
        this._profileService
            .updateCurrentUserProfile(this.user)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.appSession.user.name = this.user.name;
                this.appSession.user.surname = this.user.surname;
                this.appSession.user.userName = this.user.userName;
                this.appSession.user.emailAddress = this.user.emailAddress;
                this.spinnerService.hide()

                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
                if (abp.clock.provider.supportsMultipleTimezone && this._initialTimezone !== this.user.timezone) {
                    this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).then(() => {
                        window.location.reload();
                    });
                }
            });
    }
    ShowEmailButton: boolean = false
    GetCurrentRoles() {
        this._userServiceProxy.getCurrentRoles().subscribe(x => {
            this.CurrentRole = x;
            if (this.CurrentRole.toLowerCase() == 'owner' || this.CurrentRole.toLowerCase() == 'consultant' || this.CurrentRole.toLowerCase() == 'admin' ) {
                this.ShowEmailButton = true;
            } else if (this.CurrentRole.toLowerCase() != 'owner' && this.CurrentRole.toLowerCase() != 'consultant'
                && this.CurrentRole.toLowerCase() != 'admin') {
                this.ShowMemberFields = true;
            }
        }, error => {
            console.log(error);
        })

    }
    changePassword: boolean = false
    validPhoneNumber: boolean = true;
    verifyPhonenumber(event: any) {
        this.validPhoneNumber = true;
        if (!event.target.validity.patternMismatch && event.target.value !== "") {
            this.changePhoneNumberToVerified()
            // event.target.setCustomValidity('Please enter a valid phone number starting with 01, followed by 1 to 8 digits.');
        }else if(event.target.value !== ""){
            this.validPhoneNumber = false;
        }
        if (this.smsEnabled) {
            this.changePassword = true
            this.smsVerified = false
        }
    }

    closeModal(): void {
        this.smsmodal.hide();
    }
    smsVerified: boolean = false
    saveCode() {
        this.smsmodal.hide();
        if (this.codeForNumber == this.code) {
            this.smsVerified = true
        }
        this.code = null;
    }
    getAllSocialStatus() {
        this._AcceptedValuesService.getAllSocialStatus().subscribe(x => {
            this.SocialStatusDropDown = x;
        }, error => {
            console.log(error);
        })
    }
    profilePicture: string;

    getProfilePicture(userId: number): void {
        if (!userId) {
            this.profilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';
            return;
        }
        this._profileService.getProfilePictureByUser(userId).subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            } else {
                this.profilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';
            }
        });
    }

    private _uploaderOptions: FileUploaderOptions = {};
    public maxProfilPictureBytesUserFriendlyValue = 5;
    imageChangedEvent: any = '';
    public uploader: FileUploader;
    public useGravatarProfilePicture = true;

    FileExtension =
        ".jpeg,.png,.csv,.jpg,.jpe,.jfif,.gif,.tiff";
    fileChangeEvent(event: any): void {
        console.log(event);
        if (event.target.files[0].size > 5242880) {
            //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxProfilPictureBytesUserFriendlyValue));
            return;
        }
        let extension = event.target.files[0].name.split('.').pop();
        if (!this.FileExtension.includes(extension.toLowerCase())) {
            this.message.warn(this.l('PleaseSelectValidExtension'));
            return;

        }

        this.uploadProfilePictureInputLabel.nativeElement.innerText = event.target.files[0].name;

        this.imageChangedEvent = event;
    }

    imageCroppedFile(event: ImageCroppedEvent) {
        this.fileToken = event.base64;
        this.uploader.clearQueue();
        this.uploader.addToQueue([<File>base64ToFile(event.base64)]);
    }

    initFileUploader(): void {
        this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePicture' });
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'ProfilePicture');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.updateProfilePicture(resp.result.fileToken);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.setOptions(this._uploaderOptions);
    }

    updateProfilePicture(fileToken: string): void {
        const input = new UpdateProfilePictureInput();
        input.fileToken = fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;

        this.saving = true;
        this._profileService
            .updateProfilePicture(input)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                abp.setting.values['App.UserManagement.UseGravatarProfilePicture'] =
                    this.useGravatarProfilePicture.toString();
                abp.event.trigger('profilePictureChanged');
                this.close();
            });
    }

    updateProfilePictureToUseGravatar(event): void {
        const input = new UpdateProfilePictureInput();
        input.fileToken = this.fileToken;
        input.x = event.x;
        input.y = event.y;
        input.width = event.width;
        input.height = event.height;
        input.useGravatarProfilePicture = false;

        this.saving = true;
        this._profileService
            .updateProfilePicture(input)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                abp.setting.values['App.UserManagement.UseGravatarProfilePicture'] =
                    this.useGravatarProfilePicture.toString();
                    this.getProfilePicture(this.currentUser.id);
                abp.event.trigger('profilePictureChanged');
                // this.close();
                this.imageChangedEvent = '';
            });
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    DeleteImage() {
        this.imageChangedEvent = null;
        this.ImgEvent = null;
        this.profilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';
        this.user.deleteProfilePicture = true
        this.uploadProfilePictureInputLabel.nativeElement.innerText = null;

    }
    omit_special_char(event) {
        var k;

        k = event.charCode; //       t.k  k = eveneyCode;  (Both can be used)
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k == 8 ||
            k == 32 ||
            (k >= 1569 && k <= 1610)
        );
    }
}
