<div class="row" *ngIf="uploadedFiles.length>0">
    <div class="col-md-12">
        <div class="d-flex flex-row">
            <div class="m-3" *ngFor="let file of uploadedFiles;let i = index" style="width: 100px;">
                <a href="{{serverUrl + '/' + file.filePath}}" target="_blank">
                    <img src="{{serverUrl + '/' + file.filePath}}" class="img img-thumbnail" *ngIf="imgExt.includes(file.extension)">
                    <img src="../../../../assets/images/logos/audio.jpg" class="img img-thumbnail" *ngIf="audioEx.includes(file.extension)">
                    <img src="../../../../assets/images/logos/video.jpg" class="img img-thumbnail" *ngIf="videoEx.includes(file.extension)">
                    <div class="text-center">
                        <img src="../../../../assets/images/logos/excel.png" class="img img-thumbnail" *ngIf="fileExcelExt.includes(file.extension)">
                        <img src="../../../../assets/images/logos/word.jpg" class="img img-thumbnail" *ngIf="fileWordExt.includes(file.extension)">
                        <img src="../../../../assets/images/logos/pdf.jpg" class="img img-thumbnail" *ngIf="filePdfExt.includes(file.extension)">
                        <img src="../../../../assets/images/logos/office.jpg" class="img img-thumbnail" *ngIf="fileOtherExt.includes(file.extension)">
                        <span class="text-truncate" *ngIf="!imgExt.includes(file.extension)">
                            {{file.fileNameWithoutExtension}}
                        </span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
