<form [formGroup]="ItemForm">
    <div class="row">
        <div class="form-group col-md-6">
            <label for="Item_Name">{{l("NameAr")}} <sup>*</sup></label>
            <input type="text" id="Item_Name" class="form-control"
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" formControlName="name" minlength="3"
                maxlength="50" required />
            <validation-messages [formCtrl]="form.name"></validation-messages>
        </div>
        <div class="form-group col-md-6">
            <label for="Item_Name">{{l("NameEn")}} </label>
            <input type="text" id="Item_Name" class="form-control"
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" formControlName="nameEn" />
            <validation-messages [formCtrl]="form.nameEn"></validation-messages>
        </div>
        <div class="form-group col-md-6">
            <label for="Item_ItemClassId">{{l("ItemClass")}} <sup>*</sup></label>
            <select id="Item_ItemClassId" class="form-control p-0" required
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null"
                [disabled]="itemId!=null||itemId!=undefined" (change)="getAllItemsCategory($event.target.value)"
                formControlName="itemClass">
                <option [value]="null" selected disabled>{{l("ItemClass")}}
                </option>
                <option *ngFor="let r of Itemsclasses" [attr.selected]="item.itemClassId ==r.id ? 'selected' : null"
                    [value]="r.id">
                    {{r.name}}</option>

            </select>
        </div>

        <div class="form-group col-md-6">
            <label for="Item_ItemCategoryId">{{l("ItemCategory")}} <sup>*</sup></label>
            <select id="Item_ItemCategoryId" class="form-control p-0"
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" required
                [disabled]="itemId!=undefined ||itemId!=null" formControlName="itemCategory">
                <option [value]="null" selected disabled>{{l("ItemCategory")}}
                </option>
                <option *ngFor="let r of ItemsCategory" [attr.selected]="item.itemCategoryId ==r.id ? 'selected' : null"
                    [value]="r.id">
                    {{r.name}}</option>

            </select>
        </div>

        <div class="form-group col-md-6">
            <label for="Item_manifacturId">{{l("Manifacturs")}} </label>
            <div class="d-flex">

                <select id="Item_manifacturId" class="form-control p-0"
                    [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" formControlName="manifacturId">
                    <option [value]="null" disabled selected>{{l("Manifacturs")}}
                    </option>
                    <option *ngFor="let r of Manifacturs" [attr.selected]="item.manifacturId ==r.id ? 'selected' : null"
                        [value]="r.id">
                        {{r.name}}</option>
                </select>
                <div class="btn-group">

                    <button type="button" class="border-0 p-0 bg-transparent" *ngIf="Type!=3"
                        (click)="showManifacturModal(PageMode.Add)">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0.5 12.0001C0.5 5.3727 5.87258 0.00012207 12.5 0.00012207C19.1274 0.00012207 24.5 5.3727 24.5 12.0001C24.5 18.6275 19.1274 24.0001 12.5 24.0001C5.87258 24.0001 0.5 18.6275 0.5 12.0001ZM12.5 2.00012C6.97715 2.00012 2.5 6.47727 2.5 12.0001C2.5 17.523 6.97715 22.0001 12.5 22.0001C18.0228 22.0001 22.5 17.523 22.5 12.0001C22.5 6.47727 18.0228 2.00012 12.5 2.00012Z"
                            fill="#4EAEAF" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M17.9993 11.0834H6.99935C6.49309 11.0834 6.08268 11.4938 6.08268 12C6.08268 12.5063 6.49309 12.9167 6.99935 12.9167H17.9993C18.5056 12.9167 18.916 12.5063 18.916 12C18.916 11.4938 18.5056 11.0834 17.9993 11.0834Z"
                            fill="#4EAEAF" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M13.416 17.5002L13.416 6.5002C13.416 5.99394 13.0056 5.58354 12.4993 5.58354C11.9931 5.58354 11.5827 5.99394 11.5827 6.5002L11.5827 17.5002C11.5827 18.0065 11.9931 18.4169 12.4993 18.4169C13.0056 18.4169 13.416 18.0065 13.416 17.5002Z"
                            fill="#4EAEAF" />
                    </svg>
                    </button>

                    <button type="button" class="border-0 p-0 bg-transparent"
                        *ngIf="IsManifacturAddedByCompany&&Type!=3" (click)="showManifacturModal(PageMode.Edit)">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_6620_78603)">
                            <path
                                d="M20.4165 0.904293C19.2068 -0.303222 17.2479 -0.303222 16.0383 0.904293L14.9423 2.00624L3.27609 13.6663L3.2513 13.6913C3.2453 13.6973 3.2453 13.7037 3.2389 13.7037C3.22651 13.7223 3.20791 13.7407 3.19571 13.7593C3.19571 13.7655 3.18932 13.7655 3.18932 13.7717C3.17692 13.7903 3.17092 13.8027 3.15833 13.8213C3.15232 13.8275 3.15232 13.8335 3.14612 13.8399C3.13993 13.8584 3.13373 13.8708 3.12733 13.8894C3.12733 13.8954 3.12133 13.8954 3.12133 13.9018L0.53294 21.6854C0.45701 21.907 0.514732 22.1524 0.681506 22.3169C0.798694 22.4325 0.956752 22.4972 1.1212 22.4966C1.18841 22.4955 1.25505 22.485 1.31935 22.4656L9.09692 19.871C9.10293 19.871 9.10293 19.871 9.10932 19.865C9.12888 19.8592 9.14767 19.8509 9.16491 19.84C9.16975 19.8395 9.17401 19.8373 9.1775 19.834C9.1959 19.8216 9.2207 19.809 9.23929 19.7967C9.25769 19.7845 9.27648 19.7659 9.29508 19.7535C9.30127 19.7471 9.30728 19.7471 9.30728 19.7411C9.31367 19.7349 9.32607 19.7289 9.33226 19.7163L22.0945 6.95407C23.302 5.74443 23.302 3.78556 22.0945 2.5761L20.4165 0.904293ZM8.89877 18.4158L4.58898 14.1062L15.3758 3.31932L19.6856 7.62892L8.89877 18.4158ZM3.98193 15.2517L7.74704 19.0166L2.09337 20.899L3.98193 15.2517ZM21.2215 6.08708L20.565 6.74972L16.255 2.43974L16.9179 1.77729C17.6429 1.05286 18.8181 1.05286 19.5433 1.77729L21.2275 3.4615C21.9471 4.1898 21.9443 5.36226 21.2215 6.08708Z"
                                fill="#4EAEAF" />
                        </g>
                        <defs>
                            <clipPath id="clip0_6620_78603">
                                <rect width="24" height="24" fill="white"
                                    transform="translate(0.5 0.00012207)" />
                            </clipPath>
                        </defs>
                    </svg>
                    </button>

                </div>
            </div>
            <validation-messages [formCtrl]="form.manifacturId"></validation-messages>

        </div>

        <div class="form-group col-md-6">
            <label for="Item_UnitsNumberInPackage">{{l("UnitsNumberInPackage")}} <sup>*</sup></label>
            <input type="number" id="Item_UnitsNumberInPackage" min="1"
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" required class="form-control"
                formControlName="packageQnty" pattern="^[1-9]\d*$" />
            <validation-messages [formCtrl]="form.packageQnty"></validation-messages>

        </div>

        <div class="form-group col-md-6">
            <label for="Item_UnitId">{{l("Unit")}} <sup>*</sup></label>
            <select id="Item_UnitId" class="form-control p-0" required
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" formControlName="measureUnit">
                <option value="undefined" disabled selected>{{l("Unit")}}
                </option>
                <option *ngFor="let r of Units" [attr.selected]="item.unitId ==r.id ? 'selected' : null" [value]="r.id">
                    {{r.name}}</option>

            </select>
            <validation-messages [formCtrl]="form.measureUnit"></validation-messages>

        </div>

        <div class="form-group col-md-6">
            <label for="Item_UnitsNumberInPackage">{{l("UnitsNumberInPackage")}} <sup>*</sup></label>
            <input type="number" id="Item_UnitsNumberInPackage" min="1"
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" required class="form-control"
                formControlName="packageQnty" pattern="^[1-9]\d*$" />
            <validation-messages [formCtrl]="form.packageQnty"></validation-messages>

        </div>

        <div class="form-group col-md-6">
            <label for="Item_Link">{{l("minStockLevel")}}  <sup>*</sup></label>
            <input type="text" id="Item_Link" class="form-control"
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" formControlName="minStockLevel" />
            <validation-messages [formCtrl]="form.minStockLevel"></validation-messages>
        </div>

        <div class="form-group col-md-6">
            <label for="Item_Description">{{l("Description")}}</label>
            <input type="text" id="Item_Description" class="form-control"
                [attr.disabled]="Type==3 ||Type==4 || Type==5? 'disabled' : null" formControlName="description"
                minlength="0" maxlength="200" />
            <validation-messages [formCtrl]="form.description"></validation-messages>

        </div>
    </div>
</form>
<app-create-or-edit-manifacturs-modal #ManifacturModal (modalSave)="getAllManifacturs()">
</app-create-or-edit-manifacturs-modal>
