import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';
import { acceptedValueCategoriesEnum } from '@app/shared/Enum/AcceptedValueCategoriesEnum';
import { PageModeEnum } from '@app/shared/Enum/PageModeEnum';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ItemsServiceProxy, AcceptedValuesServiceProxy, AcceptedValueDto, CreateOrEditItemDto, CreateOrEditAcceptedValueDto, GenericDto, CreateOrEditVendorDto, VendorsServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-edit-vendor-template',
  templateUrl: './create-edit-vendor-template.component.html',
  styleUrls: ['./create-edit-vendor-template.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class CreateEditvendorTemplateComponent extends AppComponentBase implements OnInit, OnChanges {
  active = false;
  saving = false;
  urlRegEx = "(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www.[a-zA-Z0-9]+\.[^\s]{2,})";
  item: CreateOrEditItemDto = new CreateOrEditItemDto();
  PageMode = PageModeEnum
  breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(this.l("Items"), "/app/main/lookUp/items"),
  ];
  acceptedValueCategories = acceptedValueCategoriesEnum;
  Itemsclasses: AcceptedValueDto[];
  itemId;
  vendor: CreateOrEditVendorDto = new CreateOrEditVendorDto();
  id: number;
  AcceptedValues: GenericDto[];
  parentName: string;
  InitialForm: FormGroup;
  ItemClasses: AcceptedValueDto[];

  constructor(injector: Injector,
    private _vendorServiceProxy: VendorsServiceProxy,
    private _AcceptedValuesServiceProxy: AcceptedValuesServiceProxy,
    private fb: FormBuilder
  ) {
    super(injector);
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

    this.GetAllItemClasses();
    this.initForm();

  }


  show(Id?: number): void {
    this.id = Id;
    if (!Id) {
      this.vendor = new CreateOrEditVendorDto();
      this.vendor.id = Id;

      this.active = true;
    } else {
      this._vendorServiceProxy.getVendorForEdit(Id).subscribe((result) => {
        this.vendor = result.vendor;
        this.form.vendorName.setValue(this.vendor.vendorName);
        this.form.contactDetails.setValue(this.vendor.contactDetails);
        this.form.website.setValue(this.vendor.website);
        this.form.vendorItems.setValue(this.vendor.vendorItems);
        this.active = true;
      });
    }
  }
  initForm() {
    this.InitialForm = this.fb.group({
      vendorName: ['', Validators.required],
      contactDetails: [null],
      website: ['', [Validators.pattern(this.urlRegEx)]],
      vendorItems: [null, new FormArray([])],
    });
  }
  save() {
    if (this.InitialForm.valid) {
      this.saving = true;
      if (this.form.vendorItems.value.length == 0) {
        this.message.warn(this.l("PleaseChooseAtLeastOneItem"))
        return
      } this.vendor.vendorName = this.form.vendorName.value;
      this.vendor.contactDetails = this.form.contactDetails.value;
      this.vendor.website = this.form.website.value;
      this.vendor.vendorItems = this.form.vendorItems.value;
      return this._vendorServiceProxy.createOrEdit(this.vendor)
        ;
    } else {
      Swal.fire(this.l('ErrorOccured'), this.l('PleaseFillAllRequiredFields'), 'error')
      Swal.close()
    }

  }
  get form() {
    return this.InitialForm.controls;
  }
  GetAllItemClasses() {
    this._AcceptedValuesServiceProxy.getAllItemsclasses().subscribe(x => {
      this.ItemClasses = x;
    }, error => {
      console.log(error);
    })
  }
}
