import { FarmMapConsts, FarmMapItemConsts, PlantTypeConsts } from "@shared/service-proxies/service-proxies";

export class MarkerExtend extends google.maps.Marker{
    constructor(opts?: MarkerOptionsExtend){
      super(opts);
    }
  }
  
 export interface MarkerOptionsExtend extends google.maps.ReadonlyMarkerOptions{
    farmType?:FarmMapConsts;
    farmItemType?:FarmMapItemConsts;
    plantType?:PlantTypeConsts;
    itemId?:number;
  }