<div appBsModal #manifactursModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="NewPRModal"
    aria-hidden="true" [config]="{ backdrop: 'static', keyboard: !saving }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{"Add" | localize}}</span>
                </h4>
                <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-content" style="    padding: 2%;">
                <app-create-edit-manifacturs-template #template>
                </app-create-edit-manifacturs-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light font-weight-bold"
                    (click)="close()">{{"Close" | localize}}</button>
                <button type="button" class="btn btn-save font-weight-bold"
                    (click)="Save()">{{"Save" | localize}}</button>
            </div>
        </div>
    </div>
</div>
