<form [formGroup]="ItemForm" novalidate (ngSubmit)="save()" autocomplete="off">

    <div class="row">

        <div class="col-md-6">
            <div class="form-group">
                <label for="AcceptedValue_nameAr">{{ l('NameAr') }} <sup>*</sup></label>
                <input type="text" id="AcceptedValue_nameAr" class="form-control" formControlName="nameAr" name="nameAr"
                    minlength="1" maxlength="200" required />
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label for="AcceptedValue_Name">{{ l('NameEn') }} </label>
                <input type="text" id="AcceptedValue_Name" (keypress)="English_char($event)" class="form-control"
                    formControlName="name" name="name" minlength="1" maxlength="200" />
            </div>
        </div>
    </div>
</form>