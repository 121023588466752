import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CreateEditvendorTemplateComponent } from './create-edit-vendor-template/create-edit-vendor-template.component';

@Component({
  selector: 'app-create-or-edit-vendors-modal',
  templateUrl: './create-or-edit-vendor-modal.component.html',
  styleUrls: ['./create-or-edit-vendor-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class CreateOrEditvendorsModalComponent extends AppComponentBase implements OnInit {

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  categroyId: number;
  parentId: number;
  @ViewChild('vendorModal', { static: true }) modal: ModalDirective;
  @ViewChild('template', { static: true }) template: CreateEditvendorTemplateComponent;

  saving = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  show(vendorId?: number): void {
    this.template.InitialForm.reset();
    if (vendorId != undefined) {
      this.template.id = vendorId;
      this.template.show(vendorId);
    }
    this.modal.show();
  }
  close() {
    this.modal.hide();
  }
  Save() {
    this.template.save().subscribe(
      res => {
        this.modalSave.emit();
        this.modal.hide();
      }
    )
  }
}
