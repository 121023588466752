import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CreateEditItemTemplateComponent } from './create-edit-item-template/create-edit-item-template.component';

@Component({
  selector: 'app-create-or-edit-item-modal',
  templateUrl: './create-or-edit-item-modal.component.html',
  styleUrls: ['./create-or-edit-item-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class CreateOrEditItemModalComponent extends AppComponentBase implements OnInit {

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  ItemClassId: number;
  itemCategoryId: number;
  @ViewChild('ItemModal', { static: true }) modal: ModalDirective;
  @ViewChild('template', { static: true }) template: CreateEditItemTemplateComponent;

  saving = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  show(id?: number): void {
    this.template.ItemForm.reset();
    this.template.itemCategoryId = this.itemCategoryId;
    this.template.show(id);
    this.modal.show();
  }
  close() {
    this.modal.hide();
  }
  Save() {
    this.template.save().subscribe(
      res => {
        console.log("SAVEDMODAL")
        this.modalSave.emit(this.itemCategoryId);
        this.modal.hide();
      }
    )
  }
}
