<form #manifacturForm="ngForm" [formGroup]="InitialForm" novalidate (ngSubmit)="save()" autocomplete="off">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-6">
                <label for="Manifactur_Name">{{l("NameAr")}} *</label>
                <input type="text" id=" Manifactur_Name" class="form-control" formControlName="name" name="Name"
                    minlength="3" maxlength="200" required />
            </div>

            <div class="form-group col-md-6">
                <label for="Manifactur_Name">{{l("NameEn")}} </label>
                <input type="text" id=" Manifactur_NameEn" class="form-control" formControlName="nameEn" name="nameEn"
                    minlength="3" maxlength="200" />
            </div>
        </div>
    </div>
</form>
