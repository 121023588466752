
<p-fileUpload
            #fileUploader
            name="myfile[]"
            [customUpload]="true"
            [chooseLabel]=" l('choose') "
            [cancelLabel]=" l('cancel') "
            [uploadLabel]=" l('upload') "
            [auto]="enableAutoUpload"
            (onProgress)="onProgress($event)"
            (onSend)="onSend($event)"
            (onError)="onError()"
            [multiple]="multiple"
            (onClear)="onClear()"
            (onUpload)="onUpload()"
            (onBeforeUpload)="onBeforeUpload()"
            (onSelect)="onSelect($event,fileUploader)"
            (uploadHandler)="onUploadCustom($event, fileUploader)"
            [invalidFileSizeMessageDetail]="invalidFileSizeMessageDetail"
            [invalidFileSizeMessageSummary]="''"
            [maxFileSize]="maxFileLength"
            [accept]="accept">
    <ng-template pTemplate="content">
        <div class="row">
            <div class="d-flex m-3" *ngFor="let file of uploadedFiles;let i = index">
                <a style="float:right;cursor: pointer;" (click)="deleteFile(i,file)"><i class="fa fa-minus text-danger"></i></a>
                <a href="{{serverUrl + '/' + file.filePath}}" target="_blank">
                    <img src="{{serverUrl + '/' + file.filePath}}" class="img img-thumbnail" *ngIf="imgExt.includes(file.extension)">
                    <img src="../../../../assets/images/logos/audio.jpg" class="img img-thumbnail" *ngIf="audioEx.includes(file.extension)">
                    <img src="../../../../assets/images/logos/video.jpg" class="img img-thumbnail" *ngIf="videoEx.includes(file.extension)">
                    <div class="text-center">
                        <img src="../../../../assets/images/logos/excel.png" class="img img-thumbnail" *ngIf="fileExcelExt.includes(file.extension)">
                        <img src="../../../../assets/images/logos/word.jpg" class="img img-thumbnail" *ngIf="fileWordExt.includes(file.extension)">
                        <img src="../../../../assets/images/logos/pdf.jpg" class="img img-thumbnail" *ngIf="filePdfExt.includes(file.extension)">
                        <img src="../../../../assets/images/logos/office.jpg" class="img img-thumbnail" *ngIf="fileOtherExt.includes(file.extension)">
                        <span class="text-truncate" *ngIf="!imgExt.includes(file.extension)">
                            {{file.fileNameWithoutExtension}}
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <p-progressBar [value]="progress" *ngIf="showProgress" [style]="{'height': '10px'}"></p-progressBar>
            </div>
        </div>
    </ng-template>
</p-fileUpload>
