import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor() {}

  info(message: string, title: string = 'Info', options: any = {}): void {
    this.showNotification('info',message, title, options,  '#d1ecf1');
  }

  success(message: string, title: string = 'Success', options: any = {}): void {
    this.showNotification('success',message, title, options,  '#d4edda');
  }

  warn(message: string, title: string = 'Warning', options: any = {}): void {
    this.showNotification('warning',message, title, options,  '#fff3cd');
  }

  error(message: string, title: string = 'Error', options: any = {}): void {
    this.showNotification('error',message, title, options, '#f8d7da');
  }

  private showNotification(type: any, message: string, title: string, options: any,
    backgroundColor: string): void {
    Swal.fire({
      icon: null,
      text: message,
      timer: 2000,
      customClass:'message swal2-'+type,
      showConfirmButton: options.showConfirmButton || false,
      background: backgroundColor,
      ...options,
    }).then(() => {
        Swal.close()
    });
  }
}
