import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';
import { acceptedValueCategoriesEnum } from '@app/shared/Enum/AcceptedValueCategoriesEnum';
import { PageModeEnum } from '@app/shared/Enum/PageModeEnum';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ItemsServiceProxy, AcceptedValuesServiceProxy, AcceptedValueDto, CreateOrEditItemDto, CreateOrEditAcceptedValueDto, GenericDto } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-edit-acceptedValue-template',
  templateUrl: './create-edit-acceptedValue-template.component.html',
  styleUrls: ['./create-edit-acceptedValue-template.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class CreateEditAcceptedValueTemplateComponent extends AppComponentBase implements OnInit, OnChanges {
  @Input() categroyId: number;
  @Input() parentId: number;
  active = false;
  saving = false;
  item: CreateOrEditItemDto = new CreateOrEditItemDto();
  PageMode = PageModeEnum
  breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(this.l("Items"), "/app/main/lookUp/items"),
  ];
  acceptedValueCategories = acceptedValueCategoriesEnum;
  Itemsclasses: AcceptedValueDto[];
  itemId;
  acceptedValue: CreateOrEditAcceptedValueDto = new CreateOrEditAcceptedValueDto();
  id: number;
  AcceptedValues: GenericDto[];
  parentName: string;
  ItemForm: FormGroup;

  constructor(injector: Injector,
    private _acceptedValuesServiceProxy: AcceptedValuesServiceProxy,
    private fb: FormBuilder
  ) {
    super(injector);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("categroyId", this.categroyId)
    if (this.categroyId != undefined) {
      this.getAllAcceptedValuesDropDown()
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getAllAcceptedValuesDropDown();
    if (this.categroyId == this.acceptedValueCategories.Itemscategories) {
      this.parentName = this.l("Itemsclasses");
    } else if (this.categroyId == this.acceptedValueCategories.Crops) {
      this.parentName = this.l("cropClass");
    } else {
      this.parentName = this.l("Parent");
    }
  }


  show(acceptedValueId?: number): void {
    this.id = acceptedValueId;
    if (!acceptedValueId) {
      this.acceptedValue = new CreateOrEditAcceptedValueDto();
      this.acceptedValue.id = acceptedValueId;

      this.active = true;
    } else {
      this._acceptedValuesServiceProxy.getAcceptedValueForEdit(acceptedValueId).subscribe((result) => {
        this.acceptedValue = result.acceptedValue;
        this.f.name.setValue(this.acceptedValue.name);
        this.f.nameAr.setValue(this.acceptedValue.nameAr);
        this.active = true;
      });
    }
  }
  initForm() {
    this.ItemForm = this.fb.group({
      name: [''],
      nameAr: [null, Validators.required],
    });
  }
  save() {
    if (this.ItemForm.valid) {
      this.saving = true;
      if (this.itemId != undefined) {
        this.item.id = this.itemId;
      }
      this.saving = true;
      this.acceptedValue.acceptedValueCategoryId = this.categroyId;
      this.acceptedValue.parentId = this.parentId;
      this.acceptedValue.name = this.f.name.value;
      this.acceptedValue.nameAr = this.f.nameAr.value;
      if (this.id == undefined || this.id == null) {
        this.acceptedValue.id = null;
      }
      return this._acceptedValuesServiceProxy.createOrEdit(this.acceptedValue);
    } else {
      Swal.fire(this.l('ErrorOccured'), this.l('PleaseFillAllRequiredFields'), 'error')
      Swal.close()
    }

  }
  get f() {
    return this.ItemForm.controls;
  }


  getAllAcceptedValuesDropDown() {
    this._acceptedValuesServiceProxy.getAllAcceptedValuesDropDown(this.categroyId).subscribe(x => {
      this.AcceptedValues = x;
    })
  }
  English_char(event) {
    var k;
    k = event.charCode; //       t.k  k = eveneyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32

    );
  }
}
