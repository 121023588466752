import { Component, OnInit } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
})
export class ProfileSettingComponent implements OnInit {

  constructor(private _authService: AppAuthService) { 
    
  }

  ngOnInit(): void {
  }
  changePassword(): void {
    abp.event.trigger('app.show.changePasswordModal');
}
  logout(): void {
    this._authService.logout();
}
}
