import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CreateEditmanifactursTemplateComponent } from './create-edit-manifacturs-template/create-edit-manifacturs-template.component';

@Component({
  selector: 'app-create-or-edit-manifacturs-modal',
  templateUrl: './create-or-edit-manifacturs-modal.component.html',
  styleUrls: ['./create-or-edit-manifacturs-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class CreateOrEditmanifactursModalComponent extends AppComponentBase implements OnInit {

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  categroyId: number;
  parentId: number;
  @ViewChild('manifactursModal', { static: true }) modal: ModalDirective;
  @ViewChild('template', { static: true }) template: CreateEditmanifactursTemplateComponent;

  saving = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  show(id?: number): void {
    this.template.InitialForm.reset();
    this.template.show(id);
    this.modal.show();
  }
  close() {
    this.modal.hide();
  }
  Save() {
    this.template.save().subscribe(
      res => {
        this.modalSave.emit();
        this.modal.hide();
      }
    )
  }
}
