import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { guid } from '@fullcalendar/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DrawFarmDto, FarmDrawingServiceProxy, FarmMapConsts, FarmMapDto, FarmMapItemConsts } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { GMap } from 'primeng/gmap';
import Swal from 'sweetalert2';
import { MarkerExtend } from './classes/MarkerExtend';
import { PolygonExtend } from './classes/PolygonExtend';
import { FarmLines } from './Interfaces/FarmOverlays';

declare var google: any;
@Component({
  selector: 'app-draw-farm',
  templateUrl: './draw-farm.component.html',
  styleUrls: ['./draw-farm.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class DrawFarmComponent extends AppComponentBase implements OnInit, OnChanges {
  @Output() actionFire = new EventEmitter<DrawFarmDto>();
  @ViewChild("gmap") map: GMap;
  @Input() isMultiSection: boolean;
  @Input() ViewOnly: boolean;
  @Input() isAllowMarker: boolean;
  @Input() farmId: number;
  @Input() itemId: number;
  @Input() currentFarmType: FarmMapConsts;

  //////////MarkerIcons////////////
  PlantIcon:string="../../../../assets/images/farm/plant.png";
  TreeIcon:string="../../../../assets/images/farm/tree.png";
  SelectedIcon=0;

  _farmOverlays: FarmMapDto[] = [];
  _currentFarmOverlays: FarmMapDto[] = [];
  initLat = 29.945587;
  initLng = 31.231011;
  initZoom = 13;
  ////////////////MAP////////////////
  options: any={};
  overlays: any[] = [];
  dialogVisible: boolean;
  markerTitle: string;
  selectedPosition: any;
  markerPosition: FarmLines;
  infoWindow: any;
  draggable: boolean = true;
  editable: boolean = true;
  lines: FarmLines[] = [];
  ///////Configuration////////
  FarmColor="#6002ee";
  SectorColor="#09af00";
  BasinColor="#ff8d00";
  PlantColor="#aaf255";

  FarmId=FarmMapConsts.Farm;
  SectorId=FarmMapConsts.Sector;
  BasinId=FarmMapConsts.Basin;
  //isMultiSection=true;
  //isAllowMarker=true;
  ///////Overlay Counts//////
  farmLayers=0;
  sectorsLayers=0;
  BasinLayers=0;
  /////////Farm Borders//////
  farmBorders:FarmMapDto[]=[];
  sectorBorders:FarmMapDto[]=[];
  basinBorders:FarmMapDto[]=[];

  constructor(
    injector: Injector,
    private _farmDrawingService:FarmDrawingServiceProxy
  ) {
    super(injector);
  }
  
  ngOnInit(): void {
    
    this.options = {
      center: { lat: this.initLat, lng: this.initLng },
      zoom: this.initZoom,
      mapType: google.maps.MapTypeId.SATELLITE,
      disableDoubleClickZoom: true,
    };
    this.infoWindow = new google.maps.InfoWindow();
    this.initOverlays();
    //this.initAutocomplete();

  }
  setMap(event) {
    this.map = event;
    this.loadMapDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadMapDetails();
  }
  loadMapDetails() {
    if(this.farmId!=undefined){
      this._farmDrawingService.getFarmMap(this.farmId).subscribe(
      res=>{
        console.log("Polygons==>",res)
        this._currentFarmOverlays=res;
        this.DrawFirstTime();
        
        this._farmDrawingService.getFarmLocation(this.farmId).subscribe(
          res=>{
            if(res!=null){
              this.initLat=res.lat;
              this.initLng=res.lng;
              this.initZoom=res.zoom;
              if(this.initLat!=undefined){
                this.map?.map?.setCenter({
                  lat : this.initLat,
                  lng : this.initLng
                });
                this.map?.map?.setZoom(this.initZoom);
              }else{
                console.log("res===>",res)
                this.getCurrentPosition();
                // this.options = {
                //   center: { lat: this.initLat, lng: this.initLng },
                //   zoom: this.initZoom,
                //   mapType: google.maps.MapTypeId.SATELLITE,
                //   disableDoubleClickZoom: true,
                // };
                // this.map.initialize();
              }
            }else{
              this.getCurrentPosition();
            }
          }
        )
      }
    )
    }else{
      if(this.currentFarmType==FarmMapConsts.Farm){
        this.initZoom=13;
      }
      if(this.currentFarmType==FarmMapConsts.Sector){
        this.initZoom=14;
      }
      if(this.currentFarmType==FarmMapConsts.Basin){
        this.initZoom=15;
      }
      this.options = {
        center: { lat: this.initLat, lng: this.initLng },
        zoom: this.initZoom,
        mapType: google.maps.MapTypeId.SATELLITE,
        disableDoubleClickZoom: true,
      };
      this.getCurrentPosition();
    }
  }

  updateLayerCount(){
    var farms=this.overlays.filter(e=>e.farmType==FarmMapConsts.Farm);
    this.farmLayers=farms.length;
    
    var currentFarm=this._currentFarmOverlays.filter(e=>e.mapType==FarmMapConsts.Farm);
    this.farmBorders=currentFarm;
    
    if(this.currentFarmType==FarmMapConsts.Sector){
      var sectors=this.overlays.filter(e=>e.farmType==FarmMapConsts.Sector&&e.itemId==this.itemId);
      this.sectorsLayers=sectors.length;
      var currentSector=this._currentFarmOverlays.filter(e=>e.mapType==FarmMapConsts.Sector&&e.itemId==this.itemId);
      this.sectorBorders=currentSector;
    }else if(this.currentFarmType==FarmMapConsts.Basin){
      var sectors=this.overlays.filter(e=>e.farmType==FarmMapConsts.Sector);
      this.sectorsLayers=sectors.length;
      var currentSector=this._currentFarmOverlays.filter(e=>e.mapType==FarmMapConsts.Sector);
      this.sectorBorders=currentSector;
    }
    var Basins=this.overlays.filter(e=>e.farmType==FarmMapConsts.Basin&&e.itemId==this.itemId&&e.farmItemType==FarmMapItemConsts.Section);
    this.BasinLayers=Basins.length;
    var currentBasin=this._currentFarmOverlays.filter(e=>e.mapType==FarmMapConsts.Basin&&e.itemId==this.itemId);
    this.basinBorders=currentBasin;
  }
  initOverlays() {
    if (!this.overlays || !this.overlays.length) {
      this.overlays = [
        new google.maps.Polygon({
          paths: [
            { lat: 36.9177, lng: 30.7854 },
            { lat: 36.8851, lng: 30.7802 },
            { lat: 36.8829, lng: 30.8111 },
            { lat: 36.9177, lng: 30.8159 }
          ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35, draggable: this.draggable, editable: this.editable
        })
      ];
    }
  }

  getCurrentPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        this.initLat = resp.coords.latitude;
        this.initLng = resp.coords.longitude;
        console.log(this.initLat)
        console.log("options",this.options)
        this.map?.map?.setCenter({
          lat : resp.coords.latitude,
          lng : resp.coords.longitude
        });
        this.map?.map?.setZoom(this.initZoom);
        // this.options = {
        //   center: { lat: this.initLat, lng: this.initLng },
        //   zoom: this.initZoom,
        //   mapType: google.maps.MapTypeId.SATELLITE,
        //   disableDoubleClickZoom: true,
        // };
        // this.map.initialize();
        resolve(
          { lng: resp.coords.longitude, lat: resp.coords.latitude }
        );
      },
        err => {
          reject(err);
        });
    });
  }

  handleMapClick(event) {
    if (this.isAllowMarker) {
      this.dialogVisible = true;
      this.markerPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    }
  }
  handleOverlayDblClick(event) {
    //To Delete Overlay element
    
    var index = this.overlays.findIndex(e => e == event.overlay);
    this.overlays.splice(index, 1)
  }
  handleZoomChanged(event) {
  }
  handleOverlayClick(event) {
    let isMarker = event.overlay.getTitle != undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      this.infoWindow.setContent('' + title + '');
      this.infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());
      Swal.fire({
        title: this.l('Doyouwanttodeletethisplant?'),
        showDenyButton: true,
        confirmButtonText: this.l('Yes'),
        denyButtonText: this.l('No'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var index = this.overlays.findIndex(e => e == event.overlay);
          this.overlays.splice(index, 1)
          //Get Layers Count per type
          this.SaveMap();
          this.updateLayerCount();
        } else if (result.isDenied) {
          
        }
        Swal.close()
      })
      //  this.messageService.add({ severity: 'info', summary: 'Marker Selected', detail: title });
    }else{
      if(event.overlay.farmType==this.currentFarmType &&event.overlay.itemId==this.itemId){
          //   this.messageService.add({ severity: 'info', summary: 'Shape Selected', detail: '' });
          Swal.fire({
            title: this.l('Doyouwanttodeletethissection?'),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: this.l('Yes'),
            denyButtonText: this.l('No'),
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              var index = this.overlays.findIndex(e => e == event.overlay);
              this.overlays.splice(index, 1)
              //Get Layers Count per type
              this.SaveMap();
              this.updateLayerCount();
            } else if (result.isDenied) {
              
            }
            Swal.close()
          })
      }
    }
  }
  addMarker() {
    if (this.markerTitle != null) {
      var icon;
      if(this.SelectedIcon==0){
        icon=this.PlantIcon;
      }
      if(this.SelectedIcon==1){
        icon=this.TreeIcon;
      }
      this.overlays.push(new MarkerExtend(
        { position: { lat: this.markerPosition.lat, lng: this.markerPosition.lng },
         title: this.markerTitle, draggable: this.draggable,icon:icon,plantType:this.SelectedIcon,
         itemId:this.itemId,farmType:FarmMapConsts.Basin,farmItemType:FarmMapItemConsts.Marker }));
      this.dialogVisible = false;

      this.markerTitle="";
      this.SelectedIcon=1;
    }
  }
  SetAsCurrentLocation() {
    var latLng = this.map.map.getCenter();
    var zoom=this.map.map.getZoom();
    var lat = latLng.lat();
    var lng = latLng.lng();
    this.initLat = lat;
    this.initLng = lng;
    this.initZoom=zoom;
    if(this.farmId!=undefined){
      this._farmDrawingService.setFarmLocation(this.farmId,this.initLat,this.initLng,this.initZoom).subscribe(
        res=>{
          Swal.fire(this.l('LocationSet'),this.l('FarmLocationSet'),'success')
          Swal.close()
        }
      )
    }
  }
  handleDragEnd(event) {
  }


  SaveMap() {
    this._farmOverlays = [];
    this.overlays.forEach(overlay => {
      let isMarker = overlay.getTitle != undefined;
      if (isMarker) {
        var title = overlay.getTitle();
        var MarkerOverlay: FarmMapDto = new FarmMapDto();
        MarkerOverlay.mapType = FarmMapConsts.Basin;
        MarkerOverlay.mapItemType = FarmMapItemConsts.Marker;
        MarkerOverlay.lat = overlay.position.lat();
        MarkerOverlay.lng = overlay.position.lng();
        MarkerOverlay.organizationUnitId = this.farmId;
        MarkerOverlay.title = title;
        MarkerOverlay.tenantId = this.appSession.tenantId;
        MarkerOverlay.itemId = overlay.itemId;
        MarkerOverlay.plantType = overlay.plantType;
        this._farmOverlays.push(MarkerOverlay);
      } else {
        overlay.getPaths().forEach(element => {
          var paths = element.getArray();
          var title = GuidGenerator.newGuid();
          paths.forEach(path => {
            var MarkerOverlay: FarmMapDto = new FarmMapDto();
            MarkerOverlay.mapType =overlay.farmType;
            MarkerOverlay.mapItemType = overlay.farmItemType;
            MarkerOverlay.lat = path.lat();
            MarkerOverlay.lng = path.lng();
            MarkerOverlay.organizationUnitId = this.farmId;
            MarkerOverlay.title = title;
            MarkerOverlay.tenantId = this.appSession.tenantId;
            MarkerOverlay.itemId = overlay.itemId;
            this._farmOverlays.push(MarkerOverlay);
          });
        });
      }
    });
    var input:DrawFarmDto=new DrawFarmDto();
    input.farmId=this.farmId;
    input.farmMap=this._farmOverlays;
    input.farmType=this.currentFarmType;
    
    input.initLat=this.initLat;
    input.initLng=this.initLng;
    input.zoom=this.initZoom;
    // this._farmDrawingService.drawFarm(input).subscribe(
    //   res=>{

    //   }
    // )
    Swal.fire(this.l('SaveingMap'),this.l('MapChangesSavedSuccessfully'),'success');
    Swal.close()
    this.actionFire.emit(input);
  }

  DrawFirstTime() {
    this.clear();
    var sections = this._currentFarmOverlays.filter(e => e.mapItemType == FarmMapItemConsts.Section);
    this.DrawSections(sections);

    var markers = this._currentFarmOverlays.filter(e => e.mapItemType == FarmMapItemConsts.Marker);
    this.DrawMarkers(markers);

    //Get Layers Count per type
    this.updateLayerCount();
  }
  DrawMarkers(markers: FarmMapDto[]) {
    markers.forEach(marker => {
      var latLng: FarmLines = {
        lat: marker.lat,
        lng: marker.lng
      };
      
      var icon;
      if(marker.plantType==0){
        icon=this.PlantIcon;
      }
      if(marker.plantType==1){
        icon=this.TreeIcon;
      }

      this.overlays.push(new MarkerExtend({ position: { lat: latLng.lat, lng: latLng.lng }, title: marker.title,
         draggable: this.draggable,itemId:this.itemId,
         farmItemType:FarmMapItemConsts.Marker,
         farmType:FarmMapConsts.Basin,
        plantType:marker.plantType,
        icon:icon }));
    });
    
  }

  DrawSections(sections: FarmMapDto[]) {
    var grouped = _.mapValues(_.groupBy(sections, 'title'),
      clist => clist.map(sections => _.omit(sections, 'title')));
    for (let key in grouped) {
      var item = grouped[key];
      var paths: FarmLines[] = [];
      var points=item.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      points.forEach(element => {
        var point:FarmLines={
          lat:element.lat,
          lng:element.lng
        }
        paths.push(point)
      });
      var section;
      if(item[0].mapType==FarmMapConsts.Farm){
        var isEditable=(this.currentFarmType==FarmMapConsts.Farm&&this.ViewOnly==false);
         section = new PolygonExtend({
          paths: paths, strokeOpacity: 0.5, strokeWeight: 1, fillColor: this.FarmColor, fillOpacity: 0.1, draggable: isEditable, editable: isEditable,
          farmType:item[0].mapType,farmItemType:item[0].mapItemType,itemId:item[0].itemId
        })
        
      }
      else if(item[0].mapType==FarmMapConsts.Sector)
      {
        var isEditable=(item[0].itemId==this.itemId&&this.currentFarmType==FarmMapConsts.Sector&&this.ViewOnly==false);
         section = new PolygonExtend({
          paths: paths, strokeOpacity: 0.5, strokeWeight: 1, fillColor: this.SectorColor, fillOpacity: 0.2, 
          draggable: isEditable, editable: isEditable,
          farmType:item[0].mapType,farmItemType:item[0].mapItemType,itemId:item[0].itemId
        })
      }
      else if(item[0].mapType==FarmMapConsts.Basin)
      {
        var isEditable=(item[0].itemId==this.itemId&&this.currentFarmType==FarmMapConsts.Sector&&item[0].mapItemType==FarmMapItemConsts.Section&&this.ViewOnly==false);
         section = new PolygonExtend({
          paths: paths, strokeOpacity: 0.5, strokeWeight: 1, 
          fillColor: this.BasinColor, fillOpacity: 0.35, 
          draggable:isEditable, editable:isEditable,
          farmType:item[0].mapType,farmItemType:item[0].mapItemType,itemId:item[0].itemId
        })
      }
      this.attachPolygonInfoWindow(section,item[0].hoverTitle);
      this.overlays.push(section)
      // this.initLat=paths[0].lat;
      // this.initLng=paths[0].lng;
      // this.map.initialize();
      paths = [];
    }
  }
  AddFarm() {
    this.DrawPolygon(this.FarmColor,true,true,FarmMapConsts.Farm,FarmMapItemConsts.Section);
    //Get Layers Count per type
    this.updateLayerCount();
  }
  AddSector() {
    if(this.farmLayers!=0){
      this.DrawPolygon(this.SectorColor,true,true,FarmMapConsts.Sector,FarmMapItemConsts.Section);
      //Get Layers Count per type
      this.updateLayerCount();
    }else{
      Swal.fire(this.l("AddFarmFirst"),this.l("YouShouldAddFarmAreaFirst"));
      Swal.close()
    }
  }
  AddBasin() {
    if(this.sectorsLayers!=0){
      this.DrawPolygon(this.BasinColor,true,true,FarmMapConsts.Basin,FarmMapItemConsts.Section);
      //Get Layers Count per type
      this.updateLayerCount();
    }else{
      Swal.fire(this.l("AddSectorFirst"),this.l("YouShouldAddAtLeastOneSector"));
      Swal.close()
    }
  }
  addPlant() {
    if(this.BasinLayers!=0){
      if (this.isAllowMarker) {
        this.markerPosition = { lat: this.initLat, lng: this.initLng };
        this.dialogVisible = true;
      }
    }else{
      Swal.fire(this.l("AddBasinFirst"),this.l("YouShouldAddAtLeastOneBasin"));
      Swal.close()
    }
  }
  DrawPolygon(color,isDraggable,isEditable,farmType,farmItemType,hoverTitle?) {
    var polygon = new PolygonExtend({
      paths: [
        { lat: this.initLat, lng: this.initLng },
        { lat: this.initLat - 0.001, lng: this.initLng - 0.001 },
        { lat: this.initLat - 0.001, lng: this.initLng + 0.001 },
        { lat: this.initLat, lng: this.initLng + 0.001 }
      ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: color, fillOpacity: 0.35, draggable: isDraggable, editable: isEditable
      ,farmType:farmType,farmItemType:farmItemType,itemId:this.itemId
    });
    this.attachPolygonInfoWindow(polygon,hoverTitle);
    this.overlays.push(polygon);

    //this.map.map.setCenter({ lat: this.initLat, lng: this.initLng });
  }
  //Add Title Over Polygon
  farmMapConst=FarmMapConsts;
  attachPolygonInfoWindow(polygon:PolygonExtend,hoverTitle?) {
    if(hoverTitle){
      var infoWindow = new google.maps.InfoWindow();
      google.maps.event.addListener(polygon, 'mouseover', function (e) {
        infoWindow.setContent(hoverTitle);
        var latLng = e.latLng;
        infoWindow.setPosition(latLng);
        infoWindow.open(this.map);
        });
        google.maps.event.addListener(polygon,"mouseout",function(e){
          infoWindow.close(this.map);
        });
    }
}
  zoomIn(map) {
    map.setZoom(map.getZoom() + 1);
  }

  zoomOut(map) {
    map.setZoom(map.getZoom() - 1);
  }

  clear() {
    var index=this.overlays.findIndex(e=>e.farmType==this.currentFarmType&&e.itemId==this.itemId);
    if(index!=-1){
      this.overlays.splice(index,1);
    }
    //Get Layers Count per type
    this.updateLayerCount();
   // this.overlays = [];
  }

  clearAllMap() {
    Swal.fire({
      title: this.l('Do you want to clear all map?'),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: this.l('Yes'),
      denyButtonText: this.l('No'),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.clear();
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
        Swal.close()
      }
    })
  }

  distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
}



 initAutocomplete() {
  const map = new google.maps.Map(
    document.getElementById("gmap") as HTMLElement,
    {
      center: { lat:this.initLat, lng: this.initLng},
      zoom: this.initZoom,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
    }
  );

  // Create the search box and link it to the UI element.
  const input = document.getElementById("pac-input") as HTMLInputElement;
  const searchBox = new google.maps.places.SearchBox(input);

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  // Bias the SearchBox results towards current map's viewport.
  map.addListener("bounds_changed", () => {
    searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
  });

  let markers: google.maps.Marker[] = [];

  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener("places_changed", () => {
    const places = searchBox.getPlaces();

    if (places.length == 0) {
      return;
    }

    // Clear out the old markers.
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];

    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();

    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }

      const icon = {
        url: place.icon as string,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25),
      };

      // Create a marker for each place.
      markers.push(
        new google.maps.Marker({
          map,
          icon,
          title: place.name,
          position: place.geometry.location,
        })
      );

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });
}
}
class GuidGenerator {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}


