<form [formGroup]="InitialForm" novalidate (ngSubmit)="save()" autocomplete="off">
    <div class="modal-body pb-0">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="Vendor_VendorName">{{l("VendorName")}} <sup>*</sup></label>
                    <input type="text" id="Vendor_VendorName" class="form-control" formControlName="vendorName"
                        name="VendorName" minlength="5" maxlength="100" required />
                    <validation-messages [formCtrl]="form.vendorName"></validation-messages>
                </div>

            </div>            
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="Vendor_Website">{{l("Website")}}</label>
                    <input type="text" id="Vendor_Website" class="form-control" [pattern]="urlRegEx"
                        formControlName="website" name="Website" maxlength="100" />
                    <validation-messages [formCtrl]="form.website"></validation-messages>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="Vendor_ContactDetails">{{l("ContactDetails")}}</label>
                    <input type="text" id="Vendor_ContactDetails" pattern="01[0-9]{9}$" class="form-control"
                        formControlName="contactDetails" maxlength="11" name="ContactDetails" />
                    <validation-messages [formCtrl]="form.contactDetails"></validation-messages>
                </div>

            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="code"> {{"Items" | localize}}<sup>*</sup></label>
                    <ng-select class="form-select form-control p-0"  [items]="ItemClasses" bindLabel="name" name="stocks" formControlName="vendorItems"
                        bindValue="code" [multiple]="true" appendTo="body">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
</form>