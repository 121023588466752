import { FarmMapConsts, FarmMapItemConsts } from "@shared/service-proxies/service-proxies";

export class PolygonExtend extends google.maps.Polygon{
    constructor(opts?: PolygonOptionsExtend){
      super(opts);
    }
  }
  
 export interface PolygonOptionsExtend extends google.maps.PolygonOptions{
    farmType?:FarmMapConsts;
    farmItemType?:FarmMapItemConsts;
    itemId?:number;
  }