import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';
import { acceptedValueCategoriesEnum } from '@app/shared/Enum/AcceptedValueCategoriesEnum';
import { PageModeEnum } from '@app/shared/Enum/PageModeEnum';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ItemsServiceProxy, AcceptedValuesServiceProxy, AcceptedValueDto, CreateOrEditItemDto, CreateOrEditAcceptedValueDto, GenericDto, CreateOrEditManifacturDto, ManifactursServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-edit-manifacturs-template',
  templateUrl: './create-edit-manifacturs-template.component.html',
  styleUrls: ['./create-edit-manifacturs-template.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class CreateEditmanifactursTemplateComponent extends AppComponentBase implements OnInit, OnChanges {
  active = false;
  saving = false;
  item: CreateOrEditItemDto = new CreateOrEditItemDto();
  PageMode = PageModeEnum
  breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(this.l("Items"), "/app/main/lookUp/items"),
  ];
  acceptedValueCategories = acceptedValueCategoriesEnum;
  Itemsclasses: AcceptedValueDto[];
  itemId;
  manifactur: CreateOrEditManifacturDto = new CreateOrEditManifacturDto();
  id: number;
  AcceptedValues: GenericDto[];
  parentName: string;
  InitialForm: FormGroup;

  constructor(injector: Injector,
    private _manifactursServiceProxy: ManifactursServiceProxy,
    private fb: FormBuilder
  ) {
    super(injector);
  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {

    this.initForm();

  }


  show(Id?: number): void {
    this.id = Id;
    if (!Id) {
      this.manifactur = new CreateOrEditManifacturDto();
      this.manifactur.id = Id;

      this.active = true;
    } else {
      this._manifactursServiceProxy.getManifacturForEdit(Id).subscribe((result) => {
        this.manifactur = result.manifactur;
        this.f.nameEn.setValue(this.manifactur.name);
        this.f.name.setValue(this.manifactur.nameAr);
        this.active = true;
      });
    }
  }
  initForm() {
    this.InitialForm = this.fb.group({
      name: ['', Validators.required],
      nameEn: [''],
    });
  }
  save() {
    if (this.InitialForm.valid) {
      this.saving = true;
      this.saving = true;
      this.manifactur.name = this.f.nameEn.value;
      this.manifactur.nameAr = this.f.name.value;
      return this._manifactursServiceProxy.createOrEdit(this.manifactur)
        ;
    } else {
      Swal.fire(this.l('ErrorOccured'), this.l('PleaseFillAllRequiredFields'), 'error')
    }

  }
  get f() {
    return this.InitialForm.controls;
  }

}
