<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <div class="d-flex align-items-center justify-content-between heading">
            <h3 class="m-0">{{"Notifications" | localize}}</h3>
            <div role="actions" class="actions">
                <button class="btn btn-success btn-mr" (click)="openNotificationSettingsModal()">
                    <i class="fa fa-cog"></i>
                    {{"NotificationSettings" | localize}}
                </button>
                <button class="btn btn-primary" (click)="setAllNotificationsAsRead()">
                    <i class="fa fa-check"></i>
                    {{"SetAllAsRead" | localize}}
                </button>
            </div>
        </div>
                    <div class="form">
                        <div class="row mb-4">
                            <div class="col-xl-6">
                                <input class="form-control" id="StartEndRange" name="StartEndRange"
                                    #drp="bsDaterangepicker" readonly="readonly" bsDaterangepicker
                                    dateRangePickerMomentModifier [(ngModel)]="dateRange">
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <select #TargetValueFilterSelectionCombobox class="form-control"
                                        [(ngModel)]="readStateFilter" name="readStateFilter">
                                        <option value="ALL">{{"All" | localize}}</option>
                                        <option value="UNREAD">{{"Unread" | localize}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 text-right">
                                <div class="form-group">
                                    <button class="btn btn-danger btn-mr" (click)="deleteNotifications()"><i
                                            class="la la-times"></i>{{"DeleteListedNotifications" | localize}}</button>
                                    <button class="btn btn-primary mt-0" (click)="getNotifications()"><i
                                            class="fa fa-sync"></i>
                                        {{l('Refresh')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <!--<Primeng-TurboTable-Start>-->
                        <div class="col primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                            <p-table #dataTable (onLazyLoad)="getNotifications($event)"
                                [value]="primengTableHelper.records"
                                rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false"
                                [scrollable]="true" ScrollWidth="100%" scrollHeight="400px" [lazy]="true"
                                [responsive]="primengTableHelper.isResponsive">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:130px;text-align:center">
                                            {{l('Actions')}}
                                        </th>

                                        <th>
                                            {{'Notification' | localize}}
                                        </th>
                                        <th style="text-align:center">
                                            {{'CreationTime' | localize}}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr>
                                        <td style="width:130px">
                                            <button class="btn-mr btn btn-sm mt-0 btn-icon btn-primary"
                                                [attr.title]="!isRead(record) ? l('SetAsRead') : ''"
                                                (click)="setAsRead(record)" [disabled]="isRead(record)">
                                                <i class="fa fa-check" *ngIf="isRead(record)"
                                                    [attr.aria-label]="l('Read')"></i>
                                                <i class="fa fa-circle-notch" *ngIf="!isRead(record)"
                                                    [attr.aria-label]="l('Unread')"></i>
                                            </button>
                                            <button
                                                *ngIf="record.notification.data.properties.messageAr||record.notification.data.properties.messageEn"
                                                class="btn btn-sm btn-icon mt-0 btn-primary" [attr.title]="l('View')" style="margin-left: 3px !important;"
                                                (click)="viewNotificationMessage(record.notification.notificationName,this.localization.currentLanguage.name=='ar'?record.notification.data.properties.messageAr:record.notification.data.properties.messageEn)">
                                                <i class="fa fa-eye" [attr.aria-label]="l('View')"></i>
                                            </button>
                                            <button
                                                *ngIf="record.notification.data.properties.Message&&(!(record.notification.data.properties.messageAr||record.notification.data.properties.messageEn))"
                                                class=" btn btn-sm btn-icon mt-0  btn-primary" [attr.title]="l('View')" style="margin-left: 3px;"
                                                (click)="viewNotificationMessage(record.notification.notificationName,record.notification.data.properties.Message)">
                                                <i class="fa fa-eye" [attr.aria-label]="l('View')"></i>
                                            </button>
                                            <button class="btn btn-sm btn-icon mt-0 btn-danger" [attr.title]="l('Delete')"
                                                (click)="deleteNotification(record)">
                                                <i class="fa fa-times" [attr.aria-label]="l('Delete')"></i>
                                            </button>

                                        </td>

                                        <td class="NotificationMessage" style="    text-overflow: ellipsis;
                                        white-space: inherit;">

                                            <span
                                                *ngIf="record.notification.data.properties.messageAr||record.notification.data.properties.messageEn"
                                                [innerHTML]="this.localization.currentLanguage.name=='ar'?record.notification.data.properties.messageAr:record.notification.data.properties.messageEn"
                                                title="{{this.localization.currentLanguage.name=='ar'?record.notification.data.properties.messageAr:record.notification.data.properties.messageEn }}"
                                                class="{{getRowClass(record)}}">

                                            </span>

                                            <span
                                                *ngIf="record.notification.data.properties.Message&&(!(record.notification.data.properties.messageAr||record.notification.data.properties.messageEn))"
                                                [innerHTML]="l('WelcomeToTheApplicationNotificationMessage')"
                                                title="{{l('WelcomeToTheApplicationNotificationMessage')}}"
                                                class="{{getRowClass(record)}}">

                                            </span>
                                        </td>

                                        <td style="text-align:center">
                                            <span
                                                title="{{record.notification.creationTime | luxonFormat: 'yyyy-LL-dd HH:mm:ss'}}"
                                                class="{{getRowClass(record)}}">
                                                {{fromNow(record.notification.creationTime) | localize}}
                                            </span>
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                {{l('NoData')}}
                            </div>
                            <div class="primeng-paging-container">
                                <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                    (onPageChange)="getNotifications($event)"
                                    [totalRecords]="primengTableHelper.totalRecordsCount"
                                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                                </p-paginator>
                                <span class="total-records-count">
                                    {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                                </span>
                            </div>
                        </div>
                        <!--<Primeng-TurboTable-End>-->
                    </div>

        <div appBsModal #notificationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
            style="margin-top: 65px;" aria-labelledby="categorymodal" aria-hidden="true"
            [config]="{backdrop: 'static'}">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <span>{{"Message" | localize}}</span>
                        </h4>
                        <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                            <i aria-hidden="true" class="ki ki-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div [innerHTML]="viewdMeassge"></div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light-primary font-weight-bold"
                            (click)="close()">{{"Close" | localize}}</button>

                    </div>



                </div>
            </div>
        </div>

    </div>
</div>
