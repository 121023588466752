import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: "CountDown"
})
export class CountDownPipe implements PipeTransform {
    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        if (value != 0 && value! > 0) {
            return (
                ("00" + minutes).slice(-2) +
                ":" +
                ("00" + Math.floor(value - minutes * 60)).slice(-2)
            );
        }
        else {
            return ("00" + ":" + "00");
        }

    }
}