import {
    Component,
    Injector,
    OnInit,
    ViewEncapsulation,
    NgZone,
    Input,
} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    NotificationServiceProxy,
    UserNotification,
} from "@shared/service-proxies/service-proxies";
import {
    IFormattedUserNotification,
    UserNotificationHelper,
} from "./UserNotificationHelper";
import * as _ from "lodash";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import * as moment from "moment";

@Component({
    templateUrl: "./header-notifications.component.html",
    selector: "header-notifications",
    styleUrls: ['./header-notifications.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNotificationsComponent
    extends AppComponentBase
    implements OnInit {
    notifications: IFormattedUserNotification[] = [];
    unreadNotificationCount = 0;
    @Input() isDropup = false;
    @Input() customStyle = "btn btn-icon btn-dropdown btn-clean btn-lg mr-1";
    dateRange: Date[];
    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper,
        public _zone: NgZone
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.dateRange = [
            moment().add(-7, "day").startOf("day").toDate(),
            moment().endOf("day").toDate(),
        ];

        this.loadNotifications();
        this.registerToEvents();
    }

    loadNotifications(): void {
        if (UrlHelper.isInstallUrl(location.href)) {
            return;
        }

        this._notificationService
            .getUserNotifications(
                undefined,
                undefined,
                undefined,
                3,
                0
            )
            .subscribe((result) => {
                this.unreadNotificationCount = result.unreadCount;
                this.notifications = [];
                _.forEach(result.items, (item: UserNotification) => {
                    this.notifications.push(
                        this._userNotificationHelper.format(<any>item)
                    );
                });
            });
    }

    registerToEvents() {
        let self = this;

        function onNotificationReceived(userNotification) {
            self._userNotificationHelper.show(userNotification);
            self.loadNotifications();
        }

        abp.event.on("abp.notifications.received", (userNotification) => {
            self._zone.run(() => {
                onNotificationReceived(userNotification);
            });
        });

        function onNotificationsRefresh() {
            self.loadNotifications();
        }

        abp.event.on("app.notifications.refresh", () => {
            self._zone.run(() => {
                onNotificationsRefresh();
            });
        });

        function onNotificationsRead(userNotificationId) {
            for (let i = 0; i < self.notifications.length; i++) {
                if (
                    self.notifications[i].userNotificationId ===
                    userNotificationId
                ) {
                    self.notifications[i].state = "READ";
                }
            }

            self.unreadNotificationCount -= 1;
        }

        abp.event.on("app.notifications.read", (userNotificationId) => {
            self._zone.run(() => {
                onNotificationsRead(userNotificationId);
            });
        });
    }

    setAllNotificationsAsRead(): void {
        this._userNotificationHelper.setAllAsRead();
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: IFormattedUserNotification): void {
        this._userNotificationHelper.setAsRead(
            userNotification.userNotificationId
        );
        this.notifications.find(
            (e) => e.userNotificationId == userNotification.userNotificationId
        ).isUnread = false;
    }

    gotoUrl(Notification): void {
        if (Notification.isRedirect) {
            this.setNotificationAsRead(Notification);
            location.href = Notification.url;
        } else {
            this.setNotificationAsRead(Notification);
            location.reload()
        }

    }
}
