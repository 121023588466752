<div appBsModal #vendorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="NewPRModal"
    aria-hidden="true" [config]="{ backdrop: 'static', keyboard: !saving }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal_header">
                {{"Add" | localize}}
                <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5.66689 4.2374C5.27926 3.85966 4.65803 3.86725 4.27934 4.25436C3.90065 4.64146 3.9079 5.2615 4.29554 5.63924L10.6968 11.8771L4.29554 18.1149C3.9079 18.4927 3.90065 19.1127 4.27934 19.4998C4.65803 19.8869 5.27926 19.8945 5.66689 19.5168L12.1018 13.2462L18.3331 19.3184C18.7207 19.6961 19.342 19.6885 19.7207 19.3014C20.0993 18.9143 20.0921 18.2943 19.7045 17.9166L13.5067 11.8771L19.7045 5.83758C20.0921 5.45985 20.0993 4.83981 19.7207 4.45271C19.342 4.0656 18.7207 4.058 18.3331 4.43574L12.1018 10.508L5.66689 4.2374Z"
                            fill="#7C7B7B" />
                    </svg>
                </button>
            </div>
            <div class="modal-content">
                <app-create-edit-vendor-template #template>
                </app-create-edit-vendor-template>
            </div>
            <div class="d-flex align-items-center justify-content-end p_25">
                <button type="button" class="form_action_btn form_action_btn--border" (click)="close()">{{"Close" |
                    localize}}</button>
                <button type="button" class="form_action_btn" (click)="Save()">{{"Save" | localize}}</button>
            </div>
        </div>
    </div>
</div>