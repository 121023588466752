import { Injector, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { CompaniesServiceProxy, ConsultantInvitationServiceProxy, CreateOrEditCompanyDto, GetCurrentFarmsDto, TokenAuthServiceProxy, UserLoginInfoDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { LoginService } from '@account/login/login.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()],
    providers:[],
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    recaptchaSiteKey: string = AppConsts.recaptchaSiteKey;
    active = false;
    saving = false;
    weigthBase: number = 100;
    weigthRemine: number;
    TargetStartDateisvalid = false
    urlRegEx = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    emailPattern = "^[a-zA-Z]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$";
    currentUser: UserLoginInfoDto = this.appSession.user;
    Title: string;
    model: CreateOrEditCompanyDto = new CreateOrEditCompanyDto();
    ShowCompanyBtn: boolean = false;
    ShowFarmsBtn: boolean = false;
    ;
    CompantPicture = this.appRootUrl() + 'assets/common/images/CompanyProfile.svg';
    isShowSecondLanguageFields = AppConsts.isShowSecondLanguageFields;
    SelectedFarm: number;
    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    farms: GetCurrentFarmsDto[];
    CurrentRole: string;
    ShowFarmDropdown: boolean = false;
    isDualLanguages: boolean = AppConsts.isDualLanguages;

    constructor(
        injector: Injector,
        private _router: Router,
        @Inject(DOCUMENT) private document: Document,
        _dateTimeService: DateTimeService,
        private _localStorageService: LocalStorageService,
        private _userServiceProxy: UserServiceProxy,
        private _authService: AppAuthService,
        private _loginService:LoginService,
        private _appSessionService:AppSessionService,
        private _tokenAuthService:TokenAuthServiceProxy,
        private _consultantService:ConsultantInvitationServiceProxy,
        private _CompaniesService: CompaniesServiceProxy
    ) {
        super(injector, _dateTimeService);
        this.CompantPicture;
    }

    ngOnInit() {
        
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.GetCurrentRoles();
        this.SelectedFarm = this._appSessionService.OrganizationUnitId;
    }

    GetOwnerFarms() {
        this._CompaniesService.getCurrentFarms(this.currentUser.id).subscribe(x => {
            this.farms = x;
            console.log(x);
            if(x.length!=0){
                this.SelectedFarm = x.filter(function (obj) { return obj.isCurrent; })[0].id;
            }
        }, error => {
            console.log(error);
        })
    }

    getConsultantFarm(){
        this._consultantService.getConsultantAssignedFarms().subscribe(x=>{
            this.farms = x;
            if(x.length!=0){
                var isOtherSelected=x.find(e=>e.isCurrent==true);
                if(isOtherSelected==undefined){
                    this.SelectedFarm=x[0].id;
                    this.ChangeFarmTenant(this.SelectedFarm);
                }else{
                    this.SelectedFarm=isOtherSelected.id;
                }

                // this.SelectedFarm = x.filter(function (obj) { return obj.isCurrent; })[0].id;
            }
        })
    }
    GetCurrentRoles() {
        this._userServiceProxy.getCurrentRoles().subscribe(x => {
            this.CurrentRole = x;
            if (this.CurrentRole.toLowerCase() == 'owner') {
                this.ShowFarmDropdown = true;
                this.ShowCompanyBtn = true;
                this.ShowFarmsBtn = true;
                this.GetOwnerFarms();
            } else if (this.CurrentRole.toLowerCase() == 'consultant') {
                this.ShowFarmDropdown = true;
                this.ShowCompanyBtn = false;
                this.ShowFarmsBtn = false;
                this.getConsultantFarm();
            }
        }, error => {
            console.log(error);
        })

    }

    ChangeFarmTenant(id) {
        

       this._tokenAuthService.switchOrganization(parseInt(id)).subscribe(x => {
        this._loginService.login(x.accessToken,x.encryptedAccessToken,x.expireInSeconds,
            x.refreshToken,x.refreshTokenExpireInSeconds,this._loginService.rememberMe,x.twoFactorRememberClientToken);
            location.reload();
    }, error => {
            console.log(error);
        })

        // this._localStorageService.getItem("RefreshToken", (err, RefTok) => {
        //     this._userServiceProxy.setOrganizationForUser(parseInt(id)).subscribe(x => {
        //         location.reload();
        //     }, error => {
        //         console.log(error);
        //     })
        // });

    }
    ShowFarms(): void {
        this._router.navigate(['app/main/farm']);
    }
    ShowCompany(): void {
        this.saving = true;

        this._CompaniesService.getCompanyForEdit(this.currentUser.id).subscribe(x => {
            this.model = x;
            this.saving = false;
            this.modal.show();

        }, error => {
            console.log(error);
            this.saving = false;
        }
        )
    }
    save(): void {
        this.saving = true;
        if (this.model.name.trim() == "") {
            this.saving = false;
            this.model.name = "";
            return
        } if (this.model.address1.trim() == "") {
            this.saving = false;
            this.model.address1 = "";
            return
        }
        this._CompaniesService.createOrEdit(this.model).subscribe(x => {
            this.saving = false;
            this.notify.info(this.l('SavedSuccessfully'));
            this.close()
        }, error => {
            console.log(error);
            this.saving = false;
        })
    }

    createNewFarm(){
        window.location.href="/app/main/farm/create-farm";
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }
    omit_special_char(event) {
        var k;
        k = event.charCode; //       t.k  k = eveneyCode;  (Both can be used)
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k == 8 ||
            k == 32 ||
            (k >= 48 && k <= 57)
            || (k >= 1569 && k <= 1610)
        );
    }
    Number_char(event) {
        var k;
        k = event.charCode; //       t.k  k = eveneyCode;  (Both can be used)
   
        return (
            k == 43 ||
            (k >= 48 && k <= 57)
        );
    }
    ChangeCM(id){
        console.log("ID=>",id)
        if(id==0){
            this._router.navigateByUrl('/app/main/dashboard');
        }
        if(id==1){
            this._router.navigateByUrl('/app/main/farm');
        }
        if(id==2){
            this._router.navigateByUrl('/app/admin/company');
        }
        if(id==3){
            this._router.navigateByUrl('/app/admin/subscription-management');
        }
    }
}
