import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'app-files-view',
  templateUrl: './files-view.component.html',
  styleUrls: ['./files-view.component.css']
})
export class FilesViewComponent extends AppComponentBase implements OnInit {

  serverUrl: string=AppConsts.remoteServiceBaseUrl;;
  audioEx:string[]=['.mp3'];
  videoEx:string[]=['.mp4'];
  imgExt: string[] = ['.jpg', '.jpeg', '.png', '.gif']
  fileOtherExt: string[] = ['.ppt', '.pptx',]
  fileExcelExt: string[] = ['.xls', '.xlsx']
  fileWordExt: string[] = ['.doc', '.docx']
  filePdfExt: string[] = ['.pdf']
  @Input() uploadedFiles: any[] = [];
  constructor(
      injector: Injector,
  ) {
      super(injector);
  }

  ngOnInit(): void {
  }

}
