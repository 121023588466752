<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div id="kt_aside" ktOffcanvas [options]="menuCanvasOptions" class="aside aside-left aside-{{
                currentTheme.baseSettings.menu.fixedAside ? 'fixed' : 'static'
            }} d-flex flex-column flex-row-auto">
            <default-brand></default-brand>
            <div id="kt_aside_menu_wrapper" class="aside-menu-wrapper flex-column-fluid">
                <side-bar-menu></side-bar-menu>
            </div>
        </div>
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header" class="header header-{{
                    currentTheme.baseSettings.header.desktopFixedHeader ? 'fixed' : 'static'
                }} head--skin-{{ currentTheme.baseSettings.header.headerSkin }}">
                <div class="{{ containerClass }} d-flex align-items-stretch justify-content-between">
                    <!-- User ( Photo + Notifcation )-->
                    
                    <div class="d-inline-flex align-items-center">
                        <div class="logo mob_view">
                            <a routerLink="/" target="_blank">
                                <img  width="60" src="assets/common/images/login/Group_color.svg"
                                    alt="logo"/>
                            </a>
                         </div>
                        <user-menu></user-menu>
                        <header-notifications></header-notifications>
                    </div>
                    <!-- action ( dropdowns - lang + setting) -->
                    <div class="d-inline-flex align-items-center">
                        <div class="topbar-item subscription-warning d-print-none"
                            *ngIf="!isMobileDevice() && subscriptionStatusBarVisible()">
                            <subscription-notification-bar></subscription-notification-bar>
                        </div>
                        <div class="topbar-item" *ngIf="ShowCompanyBtn">
                            <div class="input-group">
                                <select class="form-control" name="SelectedFarm" id="SelectedFarm"
                                    (change)="ChangeCM($event.target.value)">
                                    <option value="0">
                                        {{l('CompanyManagement')}}
                                    </option>
                                    <option value="1">
                                        {{l('Farms')}}
                                    </option>
                                    <option value="2">
                                        {{l('CompanyProfile')}}
                                    </option>
                                    <option value="3">
                                        {{l('SubscriptionManagement')}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="topbar-item" *ngIf="ShowFarmDropdown">
                            <div class="input-group">
                                <select class="form-control" name="SelectedFarm" id="SelectedFarm"
                                    (change)="ChangeFarmTenant($event.target.value)" [(ngModel)]="SelectedFarm">
                                    <option value="undefined" disabled *ngIf="farms?.length==0">
                                        {{l("Farms")}}
                                    </option>
                                    <option [attr.selected]="q.isCurrent ? 'selected' : null" *ngFor="let q of farms"
                                        [value]="q.id">
                                        {{q.name}}
                                    </option>
                                </select>
                                <div class="input-group-append" *ngIf="ShowFarmsBtn">
                                    <button tooltip="إضافه مزرعه" class="plus-btn fa fa-plus" (click)="createNewFarm()">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <quick-theme-selection></quick-theme-selection>
                        <language-switch-dropdown></language-switch-dropdown>
                        <chat-toggle-button></chat-toggle-button>
                        <active-delegated-users-combo *ngIf="!installationMode"></active-delegated-users-combo>
                       <app-profile-setting></app-profile-setting>
                       <button class="btn p-0 burger-icon burger-icon-left mob_view" style="margin-inline-start: 15px;" id="kt_aside_mobile_toggle">
                        <span></span>
                    </button>
                    </div>
                    
                </div>
            </div>
            <div class="p-3 mobile-subscription-warning d-print-none"
                *ngIf="isMobileDevice() && subscriptionStatusBarVisible()">
                <subscription-notification-bar></subscription-notification-bar>
            </div>
            <div style="flex: 1">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
<div appBsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #companyForm="ngForm" class="login-form form" method="post" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{"CompanyProfile" | localize}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-9">

                            <div class="form-group ">
                                <input #nameInput="ngModel" disabled class="form-control " autoFocus type="text"
                                    placeholder="{{'CompanyName' | localize}} *" [(ngModel)]="model.name" name="Name"
                                    minlength="3" required maxlength="100" />
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>
                        </div>
                        <div class="col-sm-9">

                            <div class="form-group ">
                                <input #AddressInput="ngModel" (keypress)="omit_special_char($event)"
                                    onPaste="return false" autocomplete="off" class="form-control " autoFocus
                                    type="text" placeholder="{{'CompanyAddress' | localize}} *"
                                    [(ngModel)]="model.address1" name="Address1" required />
                                <validation-messages [formCtrl]="AddressInput"></validation-messages>
                            </div>
                        </div>

                        <div class="col-sm-9" *ngIf="isShowSecondLanguageFields">
                            <div class="form-group ">
                                <input #Address2Input="ngModel" (keypress)="omit_special_char($event)"
                                    onPaste="return false" autocomplete="off" class="form-control " autoFocus
                                    type="text" placeholder="{{'CompanyNameAnotherLanguage' | localize}} "
                                    [(ngModel)]="model.address2" name="Address2" />
                                <validation-messages [formCtrl]="Address2Input"></validation-messages>
                            </div>
                        </div>

                        <div class="col-sm-9">
                            <div class="form-group ">
                                <input #PhoneNumberInput="ngModel" (keypress)="Number_char($event)"
                                    onPaste="return false" autocomplete="off" class="form-control " autoFocus
                                    type="text" placeholder="{{'CompanyPhoneNumber' | localize}} *"
                                    [(ngModel)]="model.phoneNumber" name="PhoneNumber" required />
                                <validation-messages [formCtrl]="PhoneNumberInput"></validation-messages>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="form-group ">
                                <input #EmailInput="ngModel" autocomplete="off" class="form-control " autoFocus
                                    type="email" placeholder="{{'Email' | localize}} " [(ngModel)]="model.email"
                                    name="Email" [pattern]="emailPattern" />
                                <validation-messages [formCtrl]="EmailInput"></validation-messages>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="form-group ">
                                <input #websiteInput="ngModel" autocomplete="off" class="form-control " autoFocus
                                    type="text" placeholder="{{'Website' | localize}} " [(ngModel)]="model.website"
                                    name="website" [pattern]="urlRegEx" />
                                <validation-messages [formCtrl]="websiteInput"></validation-messages>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-save font-weight-bold" [disabled]="!companyForm.form.valid"
                        (click)="save()">{{"Save" | localize}}</button>
                    <button type="button" class="btn btn-light font-weight-bold" (click)="close()">{{"Close" |
                        localize}}</button>
                </div>
            </form>
        </div>
    </div>
</div>