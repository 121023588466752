<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header_mobile" class="header-mobile">
                <a routerLink="/" target="_blank">
                    <img
                        *ngIf="!appSession.tenant || !appSession.tenant.logoId"
                        src="/assets/common/images/app-logo-on-dark.svg"
                        alt="logo"
                        height="28"
                    />
                    <img
                        *ngIf="appSession.tenant && appSession.tenant.logoId"
                        [src]="
                            remoteServiceBaseUrl +
                            '/TenantCustomization/GetTenantLogo?skin=' +
                            currentTheme.baseSettings.menu.asideSkin +
                            '&tenantId=' +
                            appSession.tenant.id +
                            '&id=' +
                            appSession.tenant.logoId
                        "
                        alt="logo"
                        height="28"
                    />
                </a>
                <div class="d-flex align-items-center">
                    <button class="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
                        <span></span>
                    </button>
                    <button
                        ktToggle
                        [options]="userMenuToggleOptions"
                        class="btn p-0 ml-2"
                        id="kt_header_mobile_topbar_toggle"
                    >
                        <span class="svg-icon svg-icon-xl">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                            >
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                    <path
                                        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                        opacity="0.3"
                                    ></path>
                                    <path
                                        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                        fill="#000000"
                                        fill-rule="nonzero"
                                    ></path>
                                </g>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <div id="kt_header" ktHeader #ktHeader class="header header-fixed">
                <div class="container">
                    <div class="d-none d-lg-flex align-items-center mr-3">
                        <theme8-brand></theme8-brand>
                    </div>
                    <div class="topbar topbar-minimize">
                        <div
                            class="topbar-item subscription-warning d-print-none mr-2"
                            *ngIf="!isMobileDevice() && subscriptionStatusBarVisible()"
                        >
                            <subscription-notification-bar></subscription-notification-bar>
                        </div>
                        <active-delegated-users-combo [customStyle]="'btn-clean mr-2'"></active-delegated-users-combo>
                        <quick-theme-selection
                            [customStyle]="'btn btn-icon btn-clean h-40px w-40px mr-2'"
                        ></quick-theme-selection>
                        <language-switch-dropdown
                            [customStyle]="'btn btn-icon btn-clean h-40px w-40px mr-2'"
                        ></language-switch-dropdown>
                        <header-notifications
                            [customStyle]="'btn btn-icon btn-clean h-40px w-40px mr-2'"
                        ></header-notifications>
                        <chat-toggle-button
                            [customStyle]="'btn btn-icon btn-clean h-40px w-40px mr-2'"
                        ></chat-toggle-button>
                        <user-menu
                            [togglerCssClass]="
                                'btn btn-icon w-auto h-40px btn-clean d-flex align-items-center btn-lg px-2'
                            "
                            [textCssClass]="'text-dark-50 font-weight-bolder font-size-sm d-none d-md-inline mr-3'"
                            [symbolCssClass]="'symbol symbol-30 symbol-light-success'"
                            [symbolTextCssClass]="'symbol-label font-size-h5 font-weight-bold'"
                        ></user-menu>
                    </div>
                </div>
            </div>
            <top-bar-menu class="d-flex flex-column"></top-bar-menu>
            <div [class]="containerClass + ' d-flex flex-row flex-column-fluid'">
                <div class="main d-flex flex-column flex-row-fluid">
                    <div
                        class="mobile-subscription-warning d-print-none pt-3"
                        *ngIf="isMobileDevice() && subscriptionStatusBarVisible()"
                    >
                        <subscription-notification-bar></subscription-notification-bar>
                    </div>
                    <div style="flex: 1">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
