import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AttachmentsServiceProxy, DemoUiComponentsServiceProxy } from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { IAjaxResponse } from "@node_modules/abp-ng2-module";
import { FileUpload } from 'primeng/fileupload';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls:['./file-upload.component.css'],
    animations: [appModuleAnimation()]
})

export class FileUploadComponent extends AppComponentBase {
    @Input() pathName: string = 'Test';
    @Input() maxFileLength: number = 1048576 * 5; //5MB
    @Input() attachmentType: number = 0;
    @Input() accept: string = ''; //audio/*,video/*,image/*,application/*
    @Input() multiple: string = '';
    @Input() uploadedFiles: any[] = [];
    @Input() enableAutoUpload : boolean = false;
    @Input() fileLimit:number = null;
    @Input() showSucessMessage =false;
    @Output() fileUploaded = new EventEmitter<any[]>();
    @Output() fileDeleted = new EventEmitter<any>();
    @Output() uploadInProgress = new EventEmitter<boolean>();
    invalidFileSizeMessageDetail:string;
    private uploader: FileUploader;
    private _uploaderOptions: FileUploaderOptions = {};
    
    serverUrl: string = AppConsts.remoteServiceBaseUrl;
    progress = 0;
    messages = "";
    showProgress = false;
    audioEx:string[]=['.mp3'];
    videoEx:string[]=['.mp4'];
    imgExt: string[] = ['.jpg', '.jpeg', '.png', '.gif']
    fileOtherExt: string[] = ['.ppt', '.pptx',]
    fileExcelExt: string[] = ['.xls', '.xlsx']
    fileWordExt: string[] = ['.doc', '.docx']
    filePdfExt: string[] = ['.pdf']
    constructor(
        injector: Injector,
        private attachService: AttachmentsServiceProxy,
        private http: HttpClient
    ) {
        super(injector);

        this.initFileUploader();
        this.setMaxErrorMessage();
    }

    initFileUploader(): void {
        this.uploader = new FileUploader({ url: this.serverUrl + '/FileUpload/UploadFiles' });
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + abp.auth.getToken();
        this._uploaderOptions.removeAfterUpload = true;

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('PathName', this.pathName);
            form.append('MaxFileLength', this.maxFileLength);
            form.append('Type', this.attachmentType);
        };
     
        this.uploader.onSuccessItem = (item, response, status) => {
            
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                if (this.multiple === 'multiple') {
                    resp.result.forEach(element => {
                        this.uploadedFiles.push(element);
                    });
                    //this.uploadedFiles.push(resp.result[0]);
                } else {
                    this.uploadedFiles = [];
                    this.uploadedFiles.push(resp.result[0]);
                }

                this.fileUploaded.emit(this.uploadedFiles);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.setOptions(this._uploaderOptions);
    }
    deleteFile(index, file) {
        this.uploadedFiles.splice(index, 1);
        this.fileUploaded.emit(this.uploadedFiles);
        this.fileDeleted.emit(file);
        // this.attachService.delete(file.id).subscribe(
        //     res => {
        //         this.uploadedFiles.splice(index, 1);
        //         this.fileUploaded.emit(this.uploadedFiles);
        //         this.fileDeleted.emit(file);
        //     }
        // )
    }
    onProgress(event) {
        console.log("onProgress===>", event);
    }
    onSend(event) {
        console.log("ONSEND===>", event);
    }
    onError() {
        console.log("onError===>");
    }
    onSelect(event,fileUploader:FileUpload) {
        console.log("onSelect===>", event);
         if (this.fileLimit && event.currentFiles.length + this.uploadedFiles.length > this.fileLimit) {
           let messageError = this.l('fileLimitError');
           const finalMessage= messageError.replace('{fileLimit}',this.fileLimit.toString())
           this.message.error(finalMessage);
           fileUploader.clear();
         }
         
    }
    onClear() {
       // console.log("onClear===>");
        this.showProgress = false;
    }
    onUpload(){
       // console.log("onUpload===>");
    }
    onBeforeUpload(){
      //  console.log("onBeforeUpload===>");

    }
    onUploadCustom(event, fileUploader: FileUpload): void {
        //event.files == files to upload
        /*this.uploader.clearQueue();
        this.uploader.addToQueue(event.files);

        this.uploader.uploadAll();

        fileUploader.clear();*/
        const files = event.files;
        if (files.length === 0) {
            return;
        }
        
    //    if(this.fileLimit &&  files.length > this.fileLimit){
    //      this.uploader.clearQueue()
    //      return;
    //    }

        files.forEach(element => {
            let fileToUpload = <File>element;
            const formData = new FormData();
        formData.append('PathName', this.pathName);
        formData.append('MaxFileLength', this.maxFileLength.toString());
        formData.append('Type', this.attachmentType.toString());
        formData.append('file', fileToUpload, fileToUpload.name);
        this.http.post(this.serverUrl + '/FileUpload/UploadFiles', formData, { reportProgress: true, observe: 'events' })

            .subscribe(event => {
                this.uploadInProgress.emit(true);
                if (event.type === HttpEventType.UploadProgress) {
                    this.progress = Math.round(100 * event.loaded / event.total);
                }
                else if (event.type === HttpEventType.Response) {
                    this.messages = 'Upload success.';
                    console.log("BODY==>", event.body);
                    const resp: any = event.body;
                    if (resp.success) {
                        if (this.multiple === 'multiple') {
                            this.uploadedFiles.push(resp.result[0]);
                        } else {
                            this.uploadedFiles = [];
                            this.uploadedFiles.push(resp.result[0]);
                        }
                        if(this.showSucessMessage)
                             this.notify.success(this.l('attachmentUploadSuccess'));
                        this.fileUploaded.emit(this.uploadedFiles);
                        this.uploadInProgress.emit(false);
                        fileUploader.clear();
                        //this.showProgress = false;
                    } else {
                        this.message.error(resp.error.message);
                        //this.showProgress = false;
                        this.uploadInProgress.emit(false);

                    }
                } else if (event.type === HttpEventType.Sent) {
                    this.showProgress = true;
                    this.uploadInProgress.emit(true);

                }

            });
        });
        // let fileToUpload = <File>files[0];
        // const formData = new FormData();
        // formData.append('PathName', this.pathName);
        // formData.append('MaxFileLength', this.maxFileLength.toString());
        // formData.append('Type', this.attachmentType.toString());
        // formData.append('file', fileToUpload, fileToUpload.name);
        // this.http.post(this.serverUrl + '/FileUpload/UploadFiles', formData, { reportProgress: true, observe: 'events' })

        //     .subscribe(event => {
        //         if (event.type === HttpEventType.UploadProgress) {
        //             this.progress = Math.round(100 * event.loaded / event.total);
        //         }
        //         else if (event.type === HttpEventType.Response) {
        //             this.messages = 'Upload success.';
        //             console.log("BODY==>", event.body);
        //             const resp: any = event.body;
        //             if (resp.success) {
        //                 if (this.multiple === 'multiple') {
        //                     this.uploadedFiles.push(resp.result[0]);
        //                 } else {
        //                     this.uploadedFiles = [];
        //                     this.uploadedFiles.push(resp.result[0]);
        //                 }

        //                 this.fileUploaded.emit(this.uploadedFiles);
        //                 fileUploader.clear();
        //                 //this.showProgress = false;
        //             } else {
        //                 this.message.error(resp.error.message);
        //                 //this.showProgress = false;
        //             }
        //         } else if (event.type === HttpEventType.Sent) {
        //             this.showProgress = true;
        //         }

        //     });
    }
    setMaxErrorMessage(){
        const fileSizeLimitMB = this.maxFileLength / (1024 * 1024);
        let maxErrorMessage = this.l('fileSizeMaxError');
        this.invalidFileSizeMessageDetail = maxErrorMessage.replace('{fileSize}',fileSizeLimitMB.toString());
    }
}
