<div appBsModal #changePasswordModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changePasswordModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal_header">
                    {{ 'ChangePassword' | localize }}
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5.66689 4.2374C5.27926 3.85966 4.65803 3.86725 4.27934 4.25436C3.90065 4.64146 3.9079 5.2615 4.29554 5.63924L10.6968 11.8771L4.29554 18.1149C3.9079 18.4927 3.90065 19.1127 4.27934 19.4998C4.65803 19.8869 5.27926 19.8945 5.66689 19.5168L12.1018 13.2462L18.3331 19.3184C18.7207 19.6961 19.342 19.6885 19.7207 19.3014C20.0993 18.9143 20.0921 18.2943 19.7045 17.9166L13.5067 11.8771L19.7045 5.83758C20.0921 5.45985 20.0993 4.83981 19.7207 4.45271C19.342 4.0656 18.7207 4.058 18.3331 4.43574L12.1018 10.508L5.66689 4.2374Z"
                                fill="#7C7B7B" />
                        </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="CurrentPassword">{{ 'CurrentPassword' | localize }} <sup>*</sup></label>
                                <input id="CurrentPassword" #currentPasswordInput="ngModel" type="password"
                                    name="CurrentPassword" class="form-control" [(ngModel)]="currentPassword"
                                    required />
                                <validation-messages [formCtrl]="currentPasswordInput"></validation-messages>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="NewPassword">{{ 'NewPassword' | localize }} <sup>*</sup></label>
                                <input id="NewPassword" type="password" name="NewPassword" class="form-control"
                                    [(ngModel)]="password" #NewPassword="ngModel" validateEqual="NewPasswordRepeat"
                                    reverse="true" [requireDigit]="passwordComplexitySetting.requireDigit"
                                    [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                    [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                    [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                    [requiredLength]="passwordComplexitySetting.requiredLength" required />
                            </div>
                            <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                                <ul class="help-block text-danger" *ngIf="NewPassword.errors">
                                    <li [hidden]="!NewPassword.errors.requireDigit">
                                        {{ 'PasswordComplexity_RequireDigit_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPassword.errors.requireLowercase">
                                        {{ 'PasswordComplexity_RequireLowercase_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPassword.errors.requireUppercase">
                                        {{ 'PasswordComplexity_RequireUppercase_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPassword.errors.requireNonAlphanumeric">
                                        {{ 'PasswordComplexity_RequireNonAlphanumeric_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPassword.errors.requiredLength">
                                        {{
                                        'PasswordComplexity_RequiredLength_Hint'
                                        | localize: passwordComplexitySetting.requiredLength
                                        }}
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="NewPasswordRepeat">{{ 'NewPasswordRepeat' | localize }} <sup>*</sup></label>
                                <input id="NewPasswordRepeat" type="password" name="NewPasswordRepeat"
                                    class="form-control" [ngModel]="confirmPassword" #NewPasswordRepeat="ngModel"
                                    validateEqual="NewPassword" [requireDigit]="passwordComplexitySetting.requireDigit"
                                    [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                    [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                    [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                    [requiredLength]="passwordComplexitySetting.requiredLength" reverse="false"
                                    required />
                            </div>
                            <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                                <ul class="help-block text-danger" *ngIf="NewPasswordRepeat.errors">
                                    <li [hidden]="!NewPasswordRepeat.errors.requireDigit">
                                        {{ 'PasswordComplexity_RequireDigit_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPasswordRepeat.errors.requireLowercase">
                                        {{ 'PasswordComplexity_RequireLowercase_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPasswordRepeat.errors.requireUppercase">
                                        {{ 'PasswordComplexity_RequireUppercase_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPasswordRepeat.errors.requireNonAlphanumeric">
                                        {{ 'PasswordComplexity_RequireNonAlphanumeric_Hint' | localize }}
                                    </li>
                                    <li [hidden]="!NewPasswordRepeat.errors.requiredLength">
                                        {{
                                        'PasswordComplexity_RequiredLength_Hint'
                                        | localize: passwordComplexitySetting.requiredLength
                                        }}
                                    </li>
                                    <li [hidden]="NewPasswordRepeat.valid">{{ 'PasswordsDontMatch' | localize }}</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end ">
                        <button type="button" class="action_btn action_btn--border" (click)="close()"
                            [disabled]="saving">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="submit" class="action_btn"
                            [disabled]="!changePasswordModalForm.form.valid || saving">
                            <span>{{ 'Save' | localize }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>