<div dropdown class="dropdown dropdown-language" aria-haspopup="true" *ngIf="languages.length > 1" [dropup]="isDropup">
    <a dropdownToggle  data-toggle="dropdown">
        {{currentLanguage.displayName}}
        <svg class="ms-8"  width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5303 0.969668C9.8232 1.26256 9.8232 1.73744 9.5303 2.0303L5.5303 6.0303C5.2374 6.3232 4.7626 6.3232 4.4697 6.0303L0.469668 2.0303C0.176777 1.73744 0.176777 1.26256 0.469668 0.969668C0.762558 0.676777 1.23744 0.676777 1.53033 0.969668L5 4.4393L8.4697 0.969668C8.7626 0.676777 9.2374 0.676777 9.5303 0.969668Z" fill="#377997"/>
        </svg>
            
    </a>
    <div *dropdownMenu class="dropdown-menu lang_menu"
        [attr.aria-label]="l('Languages')">
        <ul class="m-0 p-0">
            <li *ngFor="let language of languages">
                <a href="javascript:;" (click)="changeLanguage(language.name)" class="navi-link">
                    {{ language.displayName }}
                </a>
            </li>
        </ul>
    </div>
</div>