<div class="row">
    <div class="col-lg-3 col-md-4" *ngIf="ViewOnly==false">
        <div class="plan-card p-0 px-0 text-center">
            <div class="card-header">
                {{l("Location")}}
            </div>
            <div class="card-body">
                <button type="button" class="btn btn-primary w-100 mx-0 mb-2 "
                    (click)="getCurrentPosition()">{{l("GetCurrentLocation")}}</button>
                <button type="button" class="btn btn-primary w-100 mx-0 mb-2 "
                    (click)="SetAsCurrentLocation()">{{l("SetLocation")}}</button>
            </div>
        </div>
        <div class="plan-card p-0 px-0  text-center my-5">
            <div class="card-header">
                <span>{{l("Tools")}}</span>
            </div>
            <div class="card-body">
                <button type="button" class="btn btn-primary w-100 mx-0 mb-2" *ngIf="FarmId==currentFarmType"
                    (click)="AddFarm()" [disabled]="farmLayers>=1">{{l("AddFarm")}}</button>
                <button type="button" class="btn btn-primary w-100 mx-0 mb-2" *ngIf="SectorId==currentFarmType"
                    (click)="AddSector()" [disabled]="sectorsLayers>=1">{{l("AddSector")}}</button>
                <button type="button" class="btn btn-primary w-100 mx-0 mb-2" *ngIf="BasinId==currentFarmType"
                    (click)="AddBasin()" [disabled]="BasinLayers>=1">{{l("AddBasin")}}</button>

                <!-- <button type="button" class="btn btn-success w-100 mx-0 mb-2" *ngIf="BasinId==currentFarmType"
                    (click)="addPlant()">{{l("AddPlant")}}</button> -->

                <button type="button" class="btn btn-danger w-100 mx-0 mb-2"
                    (click)="clearAllMap()">{{l("ClearFarm")}}</button>

                <button type="button" class="btn btn-success w-100 mx-0 mb-2"
                (click)="SaveMap()">{{l("SaveChanges")}}</button>
            </div>
        </div>
    </div>
    <div class="col-lg-9 col-md-8">
        <!-- <input  id="pac-input" class="controls" type="text" placeholder="Search Box" /> -->

        <p-gmap #gmap [style]="{'width':'100%','height':'500px', 'margin-bottom': '1em'}" 
            [options]="options"
            (onMapReady)="setMap($event)"
            [overlays]="overlays" 
            (onMapClick)="handleMapClick($event)" 
            (onOverlayClick)="handleOverlayClick($event)"
            (onOverlayDragEnd)="handleDragEnd($event)"></p-gmap>
    </div>
</div>
<div class="row mt-2" *ngIf="ViewOnly==false">
    <div class="col-md-12">
        <!-- <button type="button" class="btn btn-success w-100 mx-0 mb-2"
        (click)="SaveMap()">{{l("Save")}}</button> -->
        <!-- <button type="button" pButton label="SaveMap" icon="pi pi-times" (click)="SaveMap()"
            style="margin-right:.25em"></button> -->
        <!-- <button type="button" pButton label="DrawMap" icon="pi pi-times" (click)="Draw()"
            style="margin-right:.25em"></button> -->

        <p-dialog showEffect="fade" [(visible)]="dialogVisible" header="Add Plant" [style]="{width: '300px'}">
            <div class="row" *ngIf="markerPosition">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="title">Plant Name</label>
                        <input type="text" class="form-control" id="title" required [(ngModel)]="markerTitle">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="title">Plant Type</label>
                        <select [(ngModel)]="SelectedIcon" class="form-control">
                            <option value="0">Plant</option>
                            <option value="1">Tree</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="p-col-10"><input id="lat" type="text" hidden readonly pInputText
                                [(ngModel)]="markerPosition.lat"></div>
                        <div class="p-col-10"><input id="lng" type="text" hidden readonly pInputText
                                [(ngModel)]="markerPosition.lng"></div>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <div>
                    <button type="button" class="btn btn-primary" (click)="addMarker()">{{l('AddPlant')}}</button>
                </div>
            </ng-template>
        </p-dialog>
    </div>
</div>